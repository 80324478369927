import "core-js/modules/es.array.push.js";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  data() {
    return {
      defaultExpendedKeys: [],
      deleteDialogVisible: false,
      deleteTypeName: '',
      deleteTypeId: null,
      addTypeDialogVisible: false,
      addTypeTitle: '添加类型',
      addTypeForm: {
        name: '',
        value: '',
        type: ''
      },
      selectedTypeValue: '',
      defaultProps: {
        label: 'displayName',
        children: 'children',
        isLeaf: 'leaf'
      },
      mainTypeItems: [{
        typeName: 'aaa'
      }]
    };
  },
  methods: {
    handleNodeClick(data) {
      if (data.typeValue === '-1') {
        console.log('====> add subtype for type:' + data.type);
        this.addTypeTitle = '添加' + data.mainTypeName;
        this.addTypeForm.value = data.maxTypeValue + 1;
        this.addTypeForm.type = data.type;
        this.addTypeDialogVisible = true;
      }
      if (data.leaf === false) {
        this.defaultExpendedKeys = [];
        this.defaultExpendedKeys.push(data.id);
      }
    },
    handleConfirmAddType() {
      this.addTypeDialogVisible = false;
      this.requestAddType(this.addTypeForm.type, this.addTypeForm.name, this.addTypeForm.value, () => {
        this.requestMainTypeList(items => {});
      });
    },
    remove(node, data) {
      console.log('====> remove node:' + node);
      console.log('====> remove data:' + data.typeName);
      this.deleteTypeId = data.id;
      this.deleteTypeName = data.typeName;
      this.deleteDialogVisible = true;
    },
    handleCancelDeleteUser() {
      this.deleteTypeId = null;
      this.deleteDialogVisible = false;
    },
    handleConfirmDeleteUser() {
      this.requestRemoveType(this.deleteTypeId, () => {
        this.requestMainTypeList(items => {});
      });
      this.deleteTypeId = null;
      this.deleteDialogVisible = false;
    },
    handleLoadNode(node, resolve, reject) {
      console.log('====> handleLoadNode()' + node.level);
      if (node.level === 0) {
        this.requestMainTypeList(items => {
          resolve(items);
        });
      } else {
        console.log('======> node.data.typeValue:', node.data.typeValue);
        this.requestTypeList(node.data.typeValue, node.data.typeName, items => {
          resolve(items);
        });
      }
    },
    requestMainTypeList(callback) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'type': 0
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取类型列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取类型列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        this.mainTypeItems = [];
        for (var row of root.rows) {
          row['leaf'] = false;
          row['isDeleteVisible'] = false;
          row['displayName'] = row['typeName'];
          this.mainTypeItems.push(row);
        }
        callback(this.mainTypeItems);
        console.log("获取类型列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        callback([]);
        //ElMessage.error('获取类型列表失败, ' + error)
      }).finally(() => {});
    },
    requestTypeList(mainType, mainTypeName, callback) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'type': mainType
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取子类型列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取子类型列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        var items = [];
        var maxTypeValue = 0;
        for (var row of root.rows) {
          row['leaf'] = true;
          row['isDeleteVisible'] = true;
          row['style'] = 'color: #666666;';
          row['displayName'] = row['typeName'] + '[value=' + row['typeValue'] + ']';
          if (row.typeValue > maxTypeValue) {
            maxTypeValue = row.typeValue;
          }
          items.push(row);
        }
        var addItem = {
          id: '-1',
          type: mainType,
          mainTypeName: mainTypeName,
          typeName: '添加类型+',
          displayName: '添加类型+',
          typeValue: '-1',
          leaf: true,
          isDeleteVisible: false,
          style: 'color: dodgerblue;',
          maxTypeValue: maxTypeValue
        };
        items.push(addItem);
        callback(items);
        console.log("获取子类型列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        callback([]);
        //ElMessage.error('获取子类型列表失败, ' + error)
      }).finally(() => {});
    },
    requestAddType(type, typeName, typeValue, callback) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/add',
        timeout: 10000,
        data: {
          'typeName': typeName,
          'typeValue': typeValue,
          'type': type
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("添加类型失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("添加类型失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        console.log("添加类型成功!");
        callback();
      }).catch(error => {
        console.log(error);
        this.error = error;
        ElMessage.error('添加类型失败, ' + error);
      }).finally(() => {});
    },
    requestRemoveType(typeId, callback) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/remove',
        timeout: 10000,
        data: [typeId]
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("删除类型失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("删除类型失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        ElMessage.success("删除类型成功!");
        console.log("删除类型成功!");
        callback();
      }).catch(error => {
        console.log(error);
        this.error = error;
        ElMessage.error('删除类型失败, ' + error);
      }).finally(() => {});
    }
  }
};