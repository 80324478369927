export default {
  name: 'OperationLogView',
  components: {},
  data() {
    return {
      info: null,
      currentPage: 1,
      pageNum: 20,
      logList: [
        /*{
          id: 1,
          text: "操作ip:120.237.99.10 操作时间:2024-06-17 10:01:00 添加用户"
        },
        {
        operateId: 0,
        operateTime: "",
        type: 0,
        userId: 0
        }*/
      ],
      total: 0
    };
  },
  mounted() {
    this.requestOperationLogList(this.currentPage, this.pageNum);
  },
  unmounted() {},
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
      this.requestOperationLogList(this.currentPage, this.pageNum);
    },
    // 请求操作日志列表
    requestOperationLogList(pageNum, pageSize) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      this.$axios({
        method: 'post',
        url: '/operateLog/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize,
          'userId': userId,
          'type': ''
        }
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取操作日志列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取操作日志列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.logList = root.rows;
        console.log("获取操作日志列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取操作日志列表失败, ' + error)
      }).finally(() => {});
    }
  }
};