import { number } from 'echarts';
export default {
  name: 'CarRequest',
  props: {
    index: Number,
    name: String,
    type: Number,
    license_plate: String,
    fault: String,
    location: String,
    time: String
  },
  // computed: {
  //   carTypeString() {
  //     if (type == 1) {
  //       return "清扫车"
  //     } else if (type == 2) {
  //       return "巡逻车"
  //     } else if (type == 3) {
  //       return "矿卡"
  //     } else {
  //       return "未知车型"
  //     }
  //   }
  // },
  methods: {
    handleCancel() {
      this.$emit('cancelEvent', 'this.name');
    },
    handleConfirm() {
      this.$emit('confirmEvent', this.name);
    }
  }
};