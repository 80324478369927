import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "div-cabin-title"
};
const _hoisted_2 = {
  class: "div-cabin-text"
};
const _hoisted_3 = {
  class: "div-cabin-text"
};
const _hoisted_4 = {
  class: "div-cabin-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    class: "container-cabin-cell",
    style: _normalizeStyle({
      background: $options.backgroundColor
    }),
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleClickItem && $options.handleClickItem(...args))
  }, [_createElementVNode("div", _hoisted_1, _toDisplayString($props.name), 1), _createElementVNode("div", _hoisted_2, "SN：" + _toDisplayString($props.sn), 1), _createElementVNode("div", _hoisted_3, "类型：" + _toDisplayString($props.type), 1), _createElementVNode("div", _hoisted_4, "状态：" + _toDisplayString($props.state), 1)], 4);
}