import "core-js/modules/es.array.push.js";
import { BaiduMap } from 'vue-baidu-map-3x';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  components: {
    BaiduMap
  },
  data() {
    return {
      info: null,
      map: null,
      totalCabin: 0,
      totalCabinOnline: 0,
      totalCar: 0,
      totalCarOnline: 0,
      // center: { lng: 0, lat: 0 },
      // zoom: 3,
      defaultProps: {
        children: 'children',
        label: 'deviceName'
      },
      router: null,
      data: [
        /*{
          id: 1,
          label: '舱端设备（3）',
          children: [
            {
              id: 4,
              label: 'Cabin-001'
            },
            {
              id: 5,
              label: 'Cabin-002'
            },
            {
              id: 6,
              label: 'Cabin-003'
            }
         ],
        },
        {
         id: 2,
         label: '车端设备（5）',
         children: [
            {
              id: 5,
              label: 'Car-001',
            },
            {
              id: 6,
              label: 'Car-002',
            },
            {
              id: 7,
              label: 'Car-003',
            },
            {
              id: 8,
              label: 'Car-004',
            },
            {
              id: 9,
              label: 'Car-005',
            }
          ]
        }*/
      ]
    };
  },
  mounted() {
    this.router = this.$router;
    var map = new BMapGL.Map("map-baidu");
    map.enableScrollWheelZoom(true);
    var zoomCtrl = new BMapGL.ZoomControl(); // 添加缩放控件
    map.addControl(zoomCtrl);
    var cityCtrl = new BMapGL.CityListControl(); // 添加城市列表控件
    map.addControl(cityCtrl);
    var point = new BMapGL.Point(117.372458, 39.174445);
    map.centerAndZoom(point, 20);
    this.map = map;
    const points = [{
      "type": "Feature",
      "properties": {},
      "geometry": {
        "coordinates": [116.38856190676842, 39.909763838319805],
        "type": "Point"
      }
    }];
    const cluster = new Cluster.View(map);
    cluster.setData(points);
    // cluster.on(Cluster.ClusterEvent.CLICK, (e:Cluster.ClusterElement) => {
    //   console.log('ClusterEvent.CLICK',e);
    // });
    // cluster.on(Cluster.ClusterEvent.MOUSE_OUT, (e: Cluster.ClusterElement) => {
    //   console.log('ClusterEvent.MOUSE_OUT', e);
    // });
    // cluster.on(Cluster.ClusterEvent.MOUSE_OVER, (e: Cluster.ClusterElement) => {
    //   console.log('ClusterEvent.MOUSE_OVER', e);
    // });
    // cluster.on(Cluster.ClusterEvent.CHANGE, (e: [any, any]) => {
    //   console.log('ClusterEvent.CHANGE', e);
    // });

    //this.handleAddCarMarker(117.372458,39.174445, null)

    this.requestCabinDeviceList();
    this.requestCarDeviceList();
    this.requestDeviceNum();
  },
  unmounted() {},
  methods: {
    // initMap ({BMap, map}) {
    //   this.lng = 117.372
    //   this.lat = 39.174
    //   this.zoom = 15
    // },
    // handleClick(e) {

    // }
    handleNodeClick(data, node, component) {
      console.log(data.deviceName);
      var lon = 117.372458;
      var lat = 39.174445;
      if (data.gps != null && data.gps != undefined && data.gps.trim() != '') {
        var gps = data.gps.split(',');
        if (gps.length > 1) {
          lon = gps[0];
          lat = gps[1];
        }
      }
      var point = new BMapGL.Point(lon, lat);
      this.map.centerAndZoom(point, 20);
    },
    getRandomFloat(min, max) {
      return Math.random() * (max - min) + min;
    },
    handleAddCarMarker(lon, lat, data) {
      var point = new BMapGL.Point(lon, lat);
      var myIcon = new BMapGL.Icon("./map_car.png", new BMapGL.Size(52, 52), {
        // 指定定位位置。  
        // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
        // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
        // 图标中央下端的尖角位置。   
        anchor: new BMapGL.Size(26, 52)
        // 设置图片偏移。  
        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
        // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
        // imageOffset: new BMapGL.Size(0, 0)  // 设置图片偏移   
      });
      // 创建Marker标注，使用小车图标
      var marker = new BMapGL.Marker(point, {
        icon: myIcon
      });

      // marker.addEventListener("click", function() {   
      //   alert("您点击了标注");   
      // });
      this.map.addOverlay(marker); // 将标注添加到地图中

      var sn = data.sn;
      if (data.sn === null) {
        sn = '无';
      }
      var vin = data.vin;
      if (data.vin === null) {
        vin = '无';
      }
      var operatorId = data.operatorId;
      if (data.operatorId === null) {
        operatorId = '无';
      }
      var carNo = data.carNo;
      if (data.carNo === null) {
        carNo = '无';
      }
      var sContent;
      if (data === null) {
        sContent = `
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车辆编号：C001</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 远驾控制器SN：G567GHSDS5</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车辆VIN：V00565658561254</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 运营类型：远程驾驶</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 运营人员：XXX</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车辆类型：清扫车</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车牌号：粤B685545</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 驾驶员：XXX</p>
        <button id='button-detail' style='width:104px;height:36px;background-color:white;color:#14A97C;border-radius:0px;line-height:36px;border-width:0px;text-align:left;'><u>查看详情</u></button>
        <div style='margin:0px 5px 0px 5px; width:auto;height:20px;'></div>`;
      } else {
        sContent = `
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车辆编号：${data.deviceName}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 远驾控制器SN：${sn}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车辆VIN：${vin}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 运营类型：${data.operate}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 运营人员：${operatorId}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车辆类型：${data.typeName}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 车牌号：${carNo}</p>
        <p style='margin:0px 0px 0px 5px;line-height:1.5;font-size:13px;text-indent:0em'> 驾驶员：无</p>
        <button id='button-detail' style='width:104px;height:36px;background-color:white;color:#14A97C;border-radius:0px;line-height:36px;border-width:0px;text-align:left;'><u>查看详情</u></button>
        <div style='margin:0px 5px 0px 5px; width:auto;height:20px;'></div>`;
      }
      // 创建图文信息窗口

      var infoWindow = new BMapGL.InfoWindow(sContent, {
        offset: new BMapGL.Size(0, -52)
      });
      // marker添加点击事件
      const self = this;
      marker.addEventListener('click', function () {
        this.openInfoWindow(infoWindow);
        document.getElementById('button-detail').onclick = function () {
          self.$router.push({
            name: 'MonitorDetailView',
            query: {
              deviceName: data.deviceName,
              deviceId: data.deviceId,
              sn: data.sn,
              vin: data.vin,
              operate: data.operate,
              typeName: data.typeName,
              carNo: data.carNo
            }
          });
        };
      });
    },
    handleAddCabinMarker(lon, lat) {
      var point = new BMapGL.Point(lon, lat);
      var myIcon = new BMapGL.Icon("./map_cabin.png", new BMapGL.Size(52, 52), {
        // 指定定位位置。  
        // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
        // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
        // 图标中央下端的尖角位置。   
        anchor: new BMapGL.Size(26, 52)
        // 设置图片偏移。  
        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
        // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
        // imageOffset: new BMapGL.Size(0, 0)  // 设置图片偏移   
      });
      // 创建Marker标注，使用小车图标
      var marker = new BMapGL.Marker(point, {
        icon: myIcon
      });

      // marker.addEventListener("click", function() {   
      //   alert("您点击了标注");   
      // });
      this.map.addOverlay(marker); // 将标注添加到地图中
    },
    // 请求舱端设备列表
    requestCabinDeviceList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('请重新登录!');
        return;
      }
      const userId = userInfo.userId;
      this.$axios({
        method: 'post',
        url: '/device/queryCabinByPage',
        timeout: 10000,
        data: {
          'pageNum': 1,
          'pageSize': 0,
          'createdType': 0,
          'operateState': '',
          'type': ''
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取舱端设备列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取舱端设备列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        const rows = root.rows;
        var cabinItem = {
          id: 1,
          deviceName: '舱端设备（' + total + '）',
          children: rows
        };
        this.data = [cabinItem, ...this.data];
        console.log("获取舱端设备列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取舱端设备列表失败, ' + error)
      }).finally(() => {});
    },
    // 请求车端设备列表
    requestCarDeviceList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('请重新登录!');
        return;
      }
      const userId = userInfo.userId;
      this.$axios({
        method: 'post',
        url: '/device/queryVehicleByPage',
        timeout: 10000,
        data: {
          'pageNum': 1,
          'pageSize': 0,
          'createdType': 1,
          'operateState': '',
          'type': ''
        }
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取车端设备列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取车端设备列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        const rows = root.rows;
        var i = 0;
        var items = [];
        for (var data of rows) {
          var lon = 117.372458 + i * 0.0001;
          var lat = 39.174445;
          if (data.gps != null && data.gps != undefined && data.gps.trim() != '') {
            var gps = data.gps.split(',');
            if (gps.length > 1) {
              lon = gps[0];
              lat = gps[1];
            }
          }
          if (data.useState === 1) {
            data['deviceName'] = data['deviceName'] + '（在线）';
            // 地图中仅显示在线车辆
            this.handleAddCarMarker(lon, lat, data);
          } else {
            data['deviceName'] = data['deviceName'] + '（离线）';
          }
          items.push(data);
          //this.handleAddCabinMarker(lon, lat)
          i++;
        }
        var carItem = {
          id: 1,
          deviceName: '车端设备（' + total + '）',
          children: items
        };
        this.data.push(carItem);
        console.log("获取车端设备列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取车端设备列表失败, ' + error)
      }).finally(() => {});
    },
    // 获取统计信息
    requestDeviceNum() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('请重新登录!');
        return;
      }
      const userId = userInfo.userId;
      this.$axios({
        method: 'post',
        url: '/device/getDeviceOnlineStatistics',
        timeout: 10000,
        data: {
          'userId': userId
        }
      }).then(response => {
        //this.info = response
        console.log(JSON.stringify(response));
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取在线统计数据失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取在线统计数据失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const data = root.data;
        this.totalCabin = data.totalCabin;
        this.totalCabinOnline = data.totalCabinOnline;
        this.totalCar = data.totalCar;
        this.totalCarOnline = data.totalCarOnline;
        console.log("获取在线统计数据成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取在线统计数据失败, ' + error)
      }).finally(() => {});
    }
  }
};