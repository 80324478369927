import "core-js/modules/es.array.push.js";
import { dataType } from 'element-plus/es/components/table-v2/src/common';
import CarRequest from '@/components/CarRequest.vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'RemoteManagerView',
  components: {
    CarRequest
  },
  data() {
    return {
      wsUrl: 'wss://192.168.19.15:8899',
      websocket: null,
      timer: null,
      isReconnect: false,
      deleteDialogVisible: false,
      selectedIndex: -1,
      deleteOrderId: null,
      searchKeyword: null,
      currentPage: 1,
      pageSize: 6,
      statusValue: '-1',
      statusItems: [{
        value: '-1',
        label: '所有状态'
      }, {
        value: '0',
        label: '非运营'
      }, {
        value: '1',
        label: '运营'
      }],
      typeValue: '-1',
      typeFilterItems: [{
        id: '-1',
        type: '1',
        typeName: '所有类型',
        typeValue: '-1'
      }],
      typeItems: [],
      tableData: [{
        id: '001',
        name: '车001',
        license_plate: '津A00001',
        type: '清扫车',
        status: '运营'
      }],
      total: 0,
      requestItems: []
    };
  },
  mounted() {
    this.wsUrl = this.$wssURL;
    //       fetch('config.json')
    //       .then(response => response.json())
    //       .then(config => {
    //     console.log(config);
    //     this.wsUrl = config.wss
    //     })
    // .catch(error => console.error('Error loading config:', error));
    this.requestTypeList(4);
    this.requestCarDeviceList(this.currentPage, this.pageSize, this.statusValue, this.typeValue);
    //this.requestOrderList(this.currentPage, this.pageSize, this.statusValue, this.typeValue)
    this.initWebsocket();
  },
  unmounted() {
    this.isReconnect = false;
    this.destoryWebsocket();
  },
  methods: {
    handleSearch() {
      console.log('searchKeyword = ', this.searchKeyword);
      // TODO: -
    },
    handleFilter() {
      console.log('statusValue = ', this.statusValue, ', typeValue = ', this.typeValue);
      this.currentPage = 1;
      //this.requestOrderList(this.currentPage, this.pageSize, this.statusValue, this.typeValue)
      this.requestCarDeviceList(this.currentPage, this.pageSize, this.statusValue, this.typeValue);
    },
    handleCurrentChange(page) {
      console.log(page);
      this.currentPage = page;
      this.requestCarDeviceList(this.currentPage, this.pageSize, this.statusValue, this.typeValue);
    },
    handleRemoteManagerDetail(index) {
      const data = this.tableData[index];
      this.$router.push({
        name: 'RemoteManagerDetailView',
        query: {
          deviceName: data.deviceName,
          deviceId: data.deviceId,
          sn: data.sn,
          vechileGps: data.gps,
          operate: data.operate,
          typeName: data.typeName,
          carNo: data.carNo,
          vechileAddress: ''
        }
      });
      //this.$router.push('remotemanagerdetail')
    },
    handleDeleteOrder(index) {
      console.log('delete order index = ', index);
      this.selectedIndex = index;
      this.deleteDialogVisible = true;
    },
    handleCancelDeleteOrder() {
      this.deleteDialogVisible = false;
    },
    handleConfirmDeleteOrder() {
      this.deleteDialogVisible = false;
      const order = this.tableData[this.selectedIndex];
      this.requestDeleteOrder(order.orderId);
    },
    handleCancel(carName) {
      console.log('====> handleCancel', carName);
      var index = -1;
      for (var i = 0; i < this.requestItems.length; i++) {
        const item = this.requestItems[i];
        if (item.carName === carName) {
          index = i;
          break;
        }
      }
      if (index != -1) {
        this.requestItems.splice(index, 1);
      }
    },
    handleConfirm(carName) {
      console.log('====> handleConfirm', carName);
      var index = -1;
      for (var i = 0; i < this.requestItems.length; i++) {
        const item = this.requestItems[i];
        if (item.carName === carName) {
          index = i;
          break;
        }
      }
      if (index === -1) {
        return;
      }
      const item = this.requestItems[index];
      this.$router.push({
        name: 'RemoteManagerDetailView',
        query: {
          deviceName: item.car,
          deviceId: item.dev_id,
          sn: item.sn,
          vechileGps: item.location,
          operate: '',
          typeName: item.typeName,
          carNo: item.carNo,
          vechileAddress: item.address
        }
      });
      this.requestItems.splice(index, 1);
      //this.$router.push('remotemanagerdetail')
    },
    // 请求车端设备列表
    requestCarDeviceList(pageNum, pageSize, state, type) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (state === '-1') {
        state = '';
      }
      if (type === '-1') {
        type = '';
      }
      this.$axios({
        method: 'post',
        url: '/device/queryVehicleByPage',
        timeout: 10000,
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize,
          'createdType': 1,
          'operateState': state,
          'type': type
        }
      }).then(response => {
        //this.info = response
        console.log(JSON.stringify(response));
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取车端设备列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取车端设备列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.tableData = root.rows;
        console.log("获取车端设备列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取车端设备列表失败, ' + error)
      }).finally(() => {});
    },
    // 请求订单列表
    requestOrderList(pageNum, pageSize, state, type) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('当前未登录!');
        return;
      }
      if (state === '-1') {
        state = '';
      }
      if (type === '-1') {
        type = '';
      }
      this.$axios({
        method: 'post',
        url: '/order/getDispatchOrders',
        timeout: 10000,
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize,
          'operateState': state,
          'type': type
        }
      }).then(response => {
        this.info = response;
        console.log(JSON.stringify(response));
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取订单列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取订单列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.tableData = root.rows;
        console.log("获取订单列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取订单列表失败, ' + error)
      }).finally(() => {});
    },
    // 请求删除订单
    requestDeleteOrder(orderId) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('当前未登录!');
        return;
      }
      this.$axios({
        method: 'post',
        url: '/order/remove/xxx',
        timeout: 10000,
        data: [orderId]
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("删除订单失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("删除订单失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.tableData = root.rows;
        console.log("删除订单成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('删除订单失败, ' + error)
      }).finally(() => {});
    },
    // 获取设备类型
    requestTypeList(type) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'type': type
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取设备类型列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取设备类型列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        this.typeItems = root.rows;
        for (var row of root.rows) {
          this.typeFilterItems.push(row);
        }
        console.log("获取设备类型列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取设备类型列表失败, ' + error)
      }).finally(() => {});
    },
    initWebsocket() {
      this.isReconnect = true;
      this.websocket = new WebSocket(this.wsUrl);
      // this.websocket.onmessage = function(event) {
      //   console.log(`收到消息：${event.data}`)
      // }
      this.websocket.onopen = this.websocketOpen;
      this.websocket.onerror = this.websocketError;
      this.websocket.onmessage = this.websocketOnMessage;
      this.websocket.onclose = this.closeWebsocket;
    },
    destoryWebsocket() {
      this.isReconnect = false;
      this.websocket.close();
    },
    websocketOpen() {
      console.log('websocket onOpen', this.websocket.readyState);
      this.reconnectTries = 0;
      // WebSocket.CONNECTING  0   连接正在进行，但还未建立
      // WebSocket.OPEN        1   连接已经建立，消息可以在客户端和服务端进行传递
      // WebSocket.CLOSING     2   连接正在进行关闭握手
      // WebSocket.CLOSED      3   连接已经关闭，不能打开
      if (this.websocket.readyState === WebSocket.OPEN) {
        console.log('websocket已连接！');
        this.startHeartbeat();
      }
    },
    websocketError() {
      console.log('websocket onError', this.websocket.readyState);
    },
    websocketOnMessage(event) {
      console.log(`收到消息：${event.data}`);
      let root = JSON.parse(event.data);
      const op = root['op'];
      //console.log('====> op: ' + op)
      if (op === 'FAULT_REPORT') {
        const _cmdid = root['cmdid'];
        const _carName = data['car'];
        const _fault = data['fault'];
        const _location = data['location'];
        const _time = data['time'];
        var isContain = false;
        for (var item of this.requestItems) {
          if (item.carName === _carName) {
            isContain = true;
            break;
          }
        }
        if (!isContain) {
          this.requestItems.push({
            cmdid: _cmdid,
            carName: _carName,
            fault: _fault,
            location: _location,
            time: _time
          });
        }
      }
    },
    closeWebsocket() {
      console.log('websocket onClose', this.websocket.readyState);
      this.stopHeartbeat();
      this.websocket.close();
      this.reconnect();
    },
    sendMessage(type) {
      if (this.websocket.readyState === WebSocket.OPEN) {
        const ts = new Date().getTime();
        this.websocket.send(JSON.stringify({
          op: type,
          dev: this.deviceName,
          cmdid: ts
        }));
      }
    },
    reconnect() {
      if (this.reconnectTries < this.MAX_RECONNECT_TRIES) {
        this.reconnectTries++;
        setTimeout(() => {
          this.initWebsocket();
        }, 2000);
      } else {
        console.error('达到最大重连次数，停止重连');
      }
    },
    handleSuccessOrderNum() {
      // TODO: -
    },
    startHeartbeat() {
      this.timer = setInterval(() => {
        const ts = new Date().getTime();
        if (this.websocket != null) {
          this.websocket.send(JSON.stringify({
            type: 'heartbeat',
            timestamp: ts
          }));
        }
        //console.log('==> timerout:' + ts)
      }, 15 * 1000); // 每15s执行一次
    },
    stopHeartbeat() {
      if (this.timer != null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    }
  }
};