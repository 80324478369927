import "core-js/modules/es.array.push.js";
export default {
  name: 'ChangePassword',
  data() {
    return {
      captchaUrl: '',
      changePasswordForm: {
        passwordOriginal: '',
        passwordNew: '',
        passwordConfirm: ''
      }
    };
  },
  methods: {
    submitChangePassword() {
      this.$refs.loginForm.validate(valid => {
        this.$router.push('/test');
      });
    }
  }
};