import "core-js/modules/es.array.push.js";
export default {
  name: 'CarMonitorQueryView',
  components: {},
  data() {
    return {
      centerDialogVisible: false,
      addDialog: false,
      loading: false,
      formLabelWidth: 80,
      form: {
        code: '',
        vin: '',
        type: '',
        status: '',
        sn: '',
        license_plate: ''
      },
      statusValue: '',
      statusItems: [{
        value: '运营',
        label: '运营'
      }, {
        value: '非运营',
        label: '非运营'
      }],
      typeValue: '',
      typeItems: [{
        value: '清扫车（小）',
        label: '清扫车（小）'
      }, {
        value: '清扫车（中）',
        label: '清扫车（中）'
      }, {
        value: '巡逻车',
        label: '巡逻车'
      }, {
        value: '教学车',
        label: '教学车'
      }, {
        value: '小巴',
        label: '小巴'
      }, {
        value: '矿卡',
        label: '矿卡'
      }, {
        value: '物流车',
        label: '物流车'
      }, {
        value: '集卡',
        label: '集卡'
      }],
      tableData: [{
        id: '001',
        code: 'CAR001',
        vin: 'VIN000001',
        type: '清扫车（小）',
        status: '非运营',
        sn: 'SN000090036',
        license_plate: '津A00001'
      }, {
        id: '002',
        code: 'CAR002',
        vin: 'VIN000002',
        type: '清扫车（中）',
        status: '非运营',
        sn: 'SN000090038',
        license_plate: '津A00001'
      }, {
        id: '003',
        code: 'CAR003',
        vin: 'VIN000003',
        type: '巡逻车',
        status: '运营',
        sn: 'SN000090039',
        license_plate: '津A00001'
      }, {
        id: '004',
        code: 'CAR004',
        vin: 'VIN000004',
        type: '教学车',
        status: '运营',
        sn: 'SN000090037',
        license_plate: '津A00001'
      }, {
        id: '005',
        code: 'CAR005',
        vin: 'VIN000005',
        type: '清扫车（小）',
        status: '非运营',
        sn: 'SN000090036',
        license_plate: '津A00001'
      }, {
        id: '006',
        code: 'CAR006',
        vin: 'VIN000006',
        type: '清扫车（中）',
        status: '非运营',
        sn: 'SN000090038',
        license_plate: '津A00001'
      }, {
        id: '007',
        code: 'CAR007',
        vin: 'VIN000007',
        type: '巡逻车',
        status: '运营',
        sn: 'SN000090039',
        license_plate: '津A00001'
      }, {
        id: '008',
        code: 'CAR008',
        vin: 'VIN000008',
        type: '教学车',
        status: '运营',
        sn: 'SN000090047',
        license_plate: '津A00001'
      }, {
        id: '009',
        code: 'CAR009',
        vin: 'VIN000009',
        type: '巡逻车',
        status: '非运营',
        sn: 'SN000090048',
        license_plate: '津A00001'
      }]
    };
  },
  methods: {
    handleCurrentChange(page) {
      console.log(page);
    },
    handleToDetail(index) {
      console.log(index);
      this.$router.push('/carmonitordetail');
    }
  }
};