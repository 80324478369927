import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Header = _resolveComponent("Header");
  const _component_el_header = _resolveComponent("el-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createBlock(_component_el_container, {
    class: "layout-container-demo"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_header, {
      style: {
        "text-align": "center",
        "font-size": "12px"
      },
      height: "68px"
    }, {
      default: _withCtx(() => [_createVNode(_component_Header, {
        ref: "app-header",
        title: "布谷鸟科技远程驾驶管理平台",
        username: $data.username
      }, null, 8, ["username"])]),
      _: 1
    }), _createVNode(_component_router_view)]),
    _: 1
  });
}