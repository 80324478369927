export default {
  name: "Gear",
  data() {
    return {
      stylesheet_normal: "width:25%;height:30px;line-height:30px;background-color:#EEEEEE;text-align:center;",
      stylesheet_selected: "width:25%;height:30px;line-height:30px;background-color:#b0eec3;text-align:center;",
      stylesheet_p: null,
      stylesheet_r: null,
      stylesheet_n: null,
      stylesheet_d: null
    };
  },
  props: {
    gearValue: {
      tyre: String,
      default: 'N'
    }
  },
  watch: {
    gearValue(newValue, oldValue) {
      this.stylesheet_p = this.stylesheet_normal;
      this.stylesheet_r = this.stylesheet_normal;
      this.stylesheet_n = this.stylesheet_normal;
      this.stylesheet_d = this.stylesheet_normal;
      if (newValue === 'P') {
        this.stylesheet_p = this.stylesheet_selected;
      } else if (newValue === 'R') {
        this.stylesheet_r = this.stylesheet_selected;
      } else if (newValue === 'N') {
        this.stylesheet_n = this.stylesheet_selected;
      } else if (newValue === 'D') {
        this.stylesheet_d = this.stylesheet_selected;
      }
    }
  }
};