import "core-js/modules/es.array.push.js";
import { dataType } from 'element-plus/es/components/table-v2/src/common';
import md5 from 'js-md5';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'CabinManagerView',
  components: {},
  data() {
    return {
      changeUserType: 'add',
      info: null,
      addDialog: false,
      deleteDialogVisible: false,
      selectedIndex: -1,
      deleteUserName: null,
      loading: false,
      formLabelWidth: 100,
      form: {
        username: '',
        password: '',
        roleId: '',
        name: '',
        email: ''
      },
      statusValue: '-1',
      statusItems: [{
        id: -1,
        type: '11',
        typeName: '所有状态',
        typeValue: '-1'
      }],
      typeValue: '-1',
      typeItems: [],
      typeFilterItems: [{
        rid: "-1",
        roleName: "所有角色"
      }],
      tableData: [
        /*
          {
            userId: '001',
            userName: 'admin',
            roleName: '',
            roleId: '',
            createdTime: '2024-06-12 11:22:05',
            state: 1
          }
          */
      ],
      total: 0,
      pageSize: 10,
      currentPage: 1
    };
  },
  mounted() {
    this.requestRoleList();
    this.requestTypeList(11);
    this.requestUserList(this.currentPage, this.pageSize, this.typeValue, this.statusValue);
  },
  unmounted() {},
  methods: {
    handleCurrentChange(page) {
      console.log(page);
      this.currentPage = page;
      this.requestUserList(this.currentPage, this.pageSize, this.typeValue, this.statusValue);
    },
    // 筛选用户
    handleFilter() {
      this.currentPage = 1;
      this.requestUserList(this.currentPage, this.pageSize, this.typeValue, this.statusValue);
    },
    handleAddUser() {
      this.changeUserType = 'add';
      this.addDialog = true;
    },
    // 添加新用户，提交
    onSubmitClick() {
      if (this.changeUserType === 'add') {
        this.addUser();
      } else if (this.changeUserType === 'update') {
        this.updateUser(this.form.password, this.form.roleId, this.form.username, this.form.name, this.form.email);
      }
    },
    cancelForm() {
      this.addDialog = false;
    },
    handleChangeUser(index) {
      console.log('change index = ', index);
      this.selectedIndex = index;
      const item = this.tableData[index];
      this.form.username = item.userName;
      //this.form.password = item.password
      this.form.roleId = item.roleId.toString();
      //this.form.name = item.name
      //this.form.email = item.email
      this.changeUserType = 'update';
      this.addDialog = true;
    },
    // 点击删除，删除用户
    handleDeleteUser(index) {
      console.log('delete index = ', index);
      this.selectedIndex = index;
      const item = this.tableData[index];
      this.deleteUserName = item.userName;
      this.deleteDialogVisible = true;
    },
    // 点击弹框中确定按钮，取消删除用户
    handleCancelDeleteUser() {
      this.deleteUserName = null;
      this.deleteDialogVisible = false;
    },
    // 点击弹框中确定按钮，确认删除用户
    handleConfirmDeleteUser() {
      console.log('this.selectedIndex = ', this.selectedIndex);
      this.deleteUserName = null;
      this.deleteDialogVisible = false;
      const item = this.tableData[this.selectedIndex];
      const userId = item.userId;
      this.removeUser(userId);
    },
    // 请求用户列表 roleId=角色ID state=状态
    requestUserList(pageNum, pageSize, roleId, state) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (roleId == '-1') {
        roleId = '';
      }
      if (state == '-1') {
        state = '';
      }
      this.$axios({
        method: 'post',
        url: '/user/queryByPage',
        timeout: 10000,
        // data: {'created': userId, 'data': '', 'isAsc': '0', 'orderByColumn': '0', 'pageNum': '1', 'pageSize': '10', 'reasonable': 'true', 'roleId': '0', 'state': '0', 'userIdPermissionEnable': 'true'}
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize,
          'roleId': roleId,
          'state': state
        }
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("用户列表获取失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("用户列表获取失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.tableData = root.rows;
        console.log("获取用户列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('登录失败, ' + error)
      }).finally(() => {});
    },
    // 添加新用户
    addUser() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (userId === null || userId === undefined || userId.trim() === '') {
        ElMessage.error('userId为空!');
        return;
      }
      const username = this.form.username;
      if (username === null || username === undefined || username.trim() === '') {
        ElMessage.info('请输入用户名!');
        return; // 字符串为空
      }
      // 用户名正则验证

      const password = this.form.password;
      if (password === null || password === undefined || password.trim() === '') {
        ElMessage.info('请输入密码!');
        return; // 密码为空
      }
      const pwd = md5(password);
      const roleId = this.form.roleId;
      if (roleId === null || roleId === undefined || roleId.trim() === '') {
        ElMessage.info('请选择角色!');
        return; // 角色为空
      }
      const name = this.form.name;
      if (name === null || name === undefined || name.trim() === '') {
        ElMessage.info('请输入姓名!');
        //return // 角色为空
      }
      const email = this.form.email;
      if (email === null || email === undefined || email.trim() === '') {
        ElMessage.info('请输入email!');
        //return // 角色为空
      }
      this.$axios({
        method: 'post',
        url: '/user/add',
        timeout: 10000,
        data: {
          'created': userId,
          'pwd': pwd,
          'roleId': roleId,
          'userName': username
        }
      }).then(response => {
        console.log(response);
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("添加用户失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("添加用户失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.addDialog = false;
        ElMessage.success('添加用户成功!');
        console.log("添加用户成功!");
      }).catch(error => {
        console.log(error);
        //this.error = error
        ElMessage.error('添加用户失败, ' + error);
      }).finally(() => {});
    },
    // 更新用户信息
    updateUser(password, roleId, username, name, email) {
      const item = this.tableData[this.selectedIndex];
      const userId = item.userId;
      const pwd = md5(password);
      this.$axios({
        method: 'post',
        url: '/user/update',
        timeout: 10000,
        data: {
          'userId': userId,
          'pwd': pwd,
          'roleId': roleId,
          'userName': username,
          'name': name,
          'email': email
        }
      }).then(response => {
        console.log(response);
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("修改用户信息失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("修改用户信息失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.addDialog = false;
        ElMessage.success('修改用户信息成功！');
        console.log("修改用户信息成功！");
      }).catch(error => {
        console.log(error);
        //this.error = error
        ElMessage.error('修改用户信息失败, ' + error);
      }).finally(() => {});
    },
    // 删除用户
    removeUser(userId) {
      const ids = [userId];
      this.$axios({
        method: 'post',
        url: '/user/remove',
        timeout: 10000,
        data: [userId]
      }).then(response => {
        console.log(response);
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("删除用户失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("删除用户失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.tableData.splice(this.selectedIndex, 1);
        ElMessage.success('删除用户成功！');
        console.log("删除用户成功！");
      }).catch(error => {
        console.log(error);
        //this.error = error
        ElMessage.error('删除用户失败, ' + error);
      }).finally(() => {});
    },
    requestRoleList() {
      this.$axios({
        method: 'post',
        url: '/role/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 1,
          'pageSize': 20
        }
      }).then(response => {
        //this.info = response
        console.log(JSON.stringify(response));
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取用户角色列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取用户角色列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.typeItems = root.rows;
        for (var row of root.rows) {
          this.typeFilterItems.push(row);
        }
        console.log("获取用户角色列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取用户角色列表失败, ' + error)
      }).finally(() => {});
    },
    requestTypeList(type) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'type': type
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取用户状态列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取用户状态列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        for (var row of root.rows) {
          this.statusItems.push(row);
        }
        console.log("获取用户状态列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取用户状态列表失败, ' + error)
      }).finally(() => {});
    }
  }
};