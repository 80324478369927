import "core-js/modules/es.array.push.js";
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {},
  methods: {
    toView(path) {
      this.$router.push(path);
      console.log(path);
    }
  },
  mounted() {
    // let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    // if (userInfo === null) return;
    // console.log('userLogin', userInfo.isLogin)
    // if (userInfo.isLogin)
    // {

    // }
  }
};