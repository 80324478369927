import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {
      isLogin: false,
      userName: "",
      userId: "",
      sessionId: "",
      createdTime: "",
      manage: false,
      created: "",
      roleId: 0,
      state: 0,
      roleName: "",
      password: "",
      categorys: null
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
