import router from './router'
import store from './store'

// const whiteList = ['/login']  // 白名单

router.beforeEach((to, from, next) => {
    console.log('是否需要登录：', to.meta.requireLogin)
    console.log('to: ', to.path)
    console.log('from: ', from.path)
    if (to.meta.requireLogin) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let isLogin = userInfo !== null ? userInfo.isLogin : false
      console.log('userLogin:', isLogin)
      if (isLogin) {
        if (to.path === '/login') {
            // 已登录，重定向到主页
            next({ path: '/' })
        } else {
            next()
        }
      } else {
        next({
          path: '/login',
          query: { redirect: to.fullPath } // 将跳转的路由 path 作为参数，登录成功后跳转到该路由
        })
        console.log("to.fullPath:", to.fullPath)
      }
    } else {
        // 不需要登录
        next();
    }
})