import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-player"
};
const _hoisted_2 = {
  class: "container-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("video", {
    autoplay: "",
    controls: "",
    muted: "",
    width: "100%",
    height: "100%",
    id: "video-front"
  }, null, -1)), _createElementVNode("div", _hoisted_2, _toDisplayString($props.startTime), 1)]);
}