import Gear from '@/components/Gear.vue';
import flvjs from 'flv.js/dist/flv.min.js';
import SpeedPanel from '@/components/SpeedPanel.vue';
import RotateSpeedPanel from '@/components/RotateSpeedPanel.vue';
export default {
  components: {
    Gear,
    SpeedPanel,
    RotateSpeedPanel
  },
  data() {
    return {
      playButtonTitle: '播放',
      flvPlayer: null,
      isPlaying: false
    };
  },
  mounted() {
    //this.flvPlayer = this.createVideoPlayer('video-front')
    this.initBaiduMap();
  },
  unmounted() {
    this.destoryVideoPlayer(this.flvPlayer);
  },
  methods: {
    handlePlay() {
      if (this.isPlaying == true) {
        //this.flvPlayer.pause()
        this.playButtonTitle = '播放';
        this.isPlaying = false;
      } else {
        //this.flvPlayer.play()
        this.playButtonTitle = '暂停';
        this.isPlaying = true;
      }
    },
    createVideoPlayer(element_id) {
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById(element_id);
        var flvPlayer = flvjs.createPlayer({
          isLive: true,
          hasAudio: false,
          hasVideo: true,
          type: 'flv',
          url: 'http://192.168.19.103:80/live?port=1935&app=live&stream=video01' //你的url地址
        });
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        //flvPlayer.play();
        /*
        //报错重连
        flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
          console.log('errType:', errType)
          console.log('errorDetail:', errDetail)
          if (this.flvPlayer) {
              this.destoryVideoPlayer(this.flvPlayer)
              this.createVideoPlayer(element_id)
          }
        })
        */
        return flvPlayer;
      }
    },
    destoryVideoPlayer(player) {
      if (player) {
        // player.pause()
        player.unload();
        player.detachMediaElement();
        player.destroy();
        player = null;
      }
    },
    initBaiduMap() {
      var map = new BMapGL.Map("map-baidu");
      var point = new BMapGL.Point(117.372458, 39.174445);
      map.centerAndZoom(point, 20);
      var point = new BMapGL.Point(117.372458, 39.174445);
      var myIcon = new BMapGL.Icon("./map_car.png", new BMapGL.Size(52, 52), {
        // 指定定位位置。  
        // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
        // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
        // 图标中央下端的尖角位置。   
        anchor: new BMapGL.Size(26, 52)
        // 设置图片偏移。  
        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
        // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
        // imageOffset: new BMapGL.Size(0, 0)  // 设置图片偏移   
      });
      // 创建Marker标注，使用小车图标
      var marker = new BMapGL.Marker(point, {
        icon: myIcon
      });

      // marker.addEventListener("click", function() {   
      //   alert("您点击了标注");   
      // });
      map.addOverlay(marker); // 将标注添加到地图中
    }
  }
};