import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/back_white.png';
const _hoisted_1 = {
  class: "container-remote-manager-detail"
};
const _hoisted_2 = {
  class: "container-back"
};
const _hoisted_3 = {
  class: "container-car-info"
};
const _hoisted_4 = {
  class: "div-info"
};
const _hoisted_5 = {
  class: "div-car-name"
};
const _hoisted_6 = {
  class: "div-info-items"
};
const _hoisted_7 = {
  class: "div-seq"
};
const _hoisted_8 = {
  class: "div-license-plate"
};
const _hoisted_9 = {
  class: "div-type"
};
const _hoisted_10 = {
  class: "div-sn"
};
const _hoisted_11 = {
  class: "div-car-info"
};
const _hoisted_12 = {
  class: "div-car-info-items"
};
const _hoisted_13 = {
  class: "div-throttle"
};
const _hoisted_14 = {
  class: "div-brake"
};
const _hoisted_15 = {
  class: "div-wheel"
};
const _hoisted_16 = {
  class: "div-lamp"
};
const _hoisted_17 = {
  class: "div-electric"
};
const _hoisted_18 = {
  class: "div-monitor-info"
};
const _hoisted_19 = {
  class: "div-monitor-info-items"
};
const _hoisted_20 = {
  class: "div-front-camera"
};
const _hoisted_21 = {
  class: "div-right-camera"
};
const _hoisted_22 = {
  class: "div-inside-camera"
};
const _hoisted_23 = {
  class: "div-back-camera"
};
const _hoisted_24 = {
  class: "div-left-camera"
};
const _hoisted_25 = {
  class: "div-avm-camera"
};
const _hoisted_26 = {
  class: "container-operation"
};
const _hoisted_27 = {
  class: "container-cabins"
};
const _hoisted_28 = {
  class: "div-cabins"
};
const _hoisted_29 = {
  class: "container-map"
};
const _hoisted_30 = {
  class: "div-buttons"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_divider = _resolveComponent("el-divider");
  const _component_CabinCell = _resolveComponent("CabinCell");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
    class: "button-back",
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleBack && $options.handleBack(...args))
  }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
    class: "button-img-back",
    src: _imports_0
  }, null, -1), _createTextVNode(" 返回 ")]))]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString($data.deviceName), 1), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, "车辆编号：" + _toDisplayString($data.deviceId), 1), _createElementVNode("div", _hoisted_8, "车牌号：" + _toDisplayString($data.carNo), 1), _createElementVNode("div", _hoisted_9, "车辆类型：" + _toDisplayString($data.typeName), 1), _createElementVNode("div", _hoisted_10, "车辆控制器SN：" + _toDisplayString($data.sn), 1)])]), _createVNode(_component_el_divider, {
    direction: "vertical",
    style: {
      "height": "104px",
      "margin-top": "16px",
      "background": "#c5c5c5"
    }
  }), _createElementVNode("div", _hoisted_11, [_cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "div-title"
  }, "车身信息", -1)), _createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, "油门：" + _toDisplayString($data.throttle), 1), _createElementVNode("div", _hoisted_14, "刹车：" + _toDisplayString($data.brake), 1), _createElementVNode("div", _hoisted_15, "转向：" + _toDisplayString($data.turnLight), 1), _createElementVNode("div", _hoisted_16, "档位：" + _toDisplayString($data.gear), 1), _createElementVNode("div", _hoisted_17, "油量/电量：" + _toDisplayString($data.battery), 1)])]), _createVNode(_component_el_divider, {
    direction: "vertical",
    style: {
      "height": "104px",
      "margin-top": "16px",
      "background": "#c5c5c5"
    }
  }), _createElementVNode("div", _hoisted_18, [_cache[5] || (_cache[5] = _createElementVNode("div", {
    class: "div-title"
  }, "监控信息", -1)), _createElementVNode("div", _hoisted_19, [_createElementVNode("div", _hoisted_20, "前向摄像头：" + _toDisplayString($data.frontCameraStatus), 1), _createElementVNode("div", _hoisted_21, "右向摄像头：" + _toDisplayString($data.rightCameraStatus), 1), _createElementVNode("div", _hoisted_22, "车内摄像头：" + _toDisplayString($data.insideCameraStatus), 1), _createElementVNode("div", _hoisted_23, "后向摄像头：" + _toDisplayString($data.backCameraStatus), 1), _createElementVNode("div", _hoisted_24, "左向摄像头：" + _toDisplayString($data.leftCameraStatus), 1), _createElementVNode("div", _hoisted_25, "环视摄像头：" + _toDisplayString($data.avmCameraStatus), 1)])])]), _createElementVNode("div", _hoisted_26, [_createElementVNode("div", _hoisted_27, [_cache[6] || (_cache[6] = _createElementVNode("div", {
    class: "div-cabins-title"
  }, "选择驾驶舱", -1)), _createElementVNode("div", _hoisted_28, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.cabinList, item => {
    return _openBlock(), _createBlock(_component_CabinCell, {
      class: "div-cabin",
      deviceId: item.deviceId,
      name: item.deviceName,
      state: item.state,
      sn: item.sn,
      type: item.typeName,
      deiverId: item.driverId,
      address: item.address,
      selected: item.selected,
      onConfirmEvent: $options.handleConfirmCabin
    }, null, 8, ["deviceId", "name", "state", "sn", "type", "deiverId", "address", "selected", "onConfirmEvent"]);
  }), 256))])]), _createElementVNode("div", _hoisted_29, [_cache[7] || (_cache[7] = _createElementVNode("div", {
    class: "div-map-title"
  }, "确认救援地点", -1)), _cache[8] || (_cache[8] = _createElementVNode("div", {
    class: "div-map",
    id: "map-baidu"
  }, "地图", -1)), _createElementVNode("div", _hoisted_30, [_createElementVNode("button", {
    class: "button-confirm",
    onClick: _cache[1] || (_cache[1] = (...args) => $options.handleConfirmOrder && $options.handleConfirmOrder(...args))
  }, "调度"), _createElementVNode("button", {
    class: "button-cancel",
    onClick: _cache[2] || (_cache[2] = (...args) => $options.handleCancelOrder && $options.handleCancelOrder(...args))
  }, "取消")])])])]);
}