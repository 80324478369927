import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from 'axios'
// import JSEncrypt from 'jsencrypt'
import JSEncrypt from 'encryptlong'
import './permission.js'

// var baseURL = 'https://120.237.99.10:38082'
// var wssURL = 'wss://120.237.99.10:38899'
// var baseURL = 'https://192.168.19.15:8082'
// var wssURL = 'wss://192.168.19.15:8899'
var baseURL = 'https://rds.cokobox.com/api/'
var wssURL = 'wss://rds.cokobox.com/ws/'

fetch('config.json')
.then(response => response.json())
.then(config => {
    console.log(config);
    //baseURL = config.server
    //wssURL = config.wss
})
.catch(error => console.error('Error loading config:', error));

const app = createApp(App)

axios.defaults.baseURL = baseURL
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.withCredentials = true


// http request 拦截器
axios.interceptors.request.use(config => {
    const jsonReqBody = config.data
    const strReqBody = JSON.stringify(jsonReqBody) 
    console.log('strReqBody=', strReqBody) 
    const randomNum = Math.floor(Math.random() * 89999999 + 10000000)
    const timestamp = Date.now()
    var uncryptedText = 'reqBody=' + strReqBody + '&rnumbers=' + randomNum + '&timestamp=' + timestamp
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo != null) {
      const isLogin = userInfo.isLogin
      if (isLogin) {
        //config.headers.Authorization = `token ${store.state.token}`;
        const userId = userInfo.userId
        const userSession = userInfo.sessionId
        // alert("userId = " + userId + ", userSession = " + userSession)
        uncryptedText += "&userId=" + userId + '&userSession=' + userSession
        config.headers['userId'] = userId
        config.headers['userSession'] = userSession
      }
    }
    var encryptor = new JSEncrypt()
    // const pubKey = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDALR8Y4Tfro2OESGiE7HMx8UEpA51QbWsb5Y5SvKZTJ7l3vA2UrryeMbEwBCTJPepfbfgv6TjncEUQ3VrkjqHdph2KpRVPi4CwVgAETwL4DVGGGFrP67SLulksdANYh5qFeC6mK3vKbS5veGsPhoxEV2bZh+99Gy//vtK3sZ6WuQIDAQAB-----END PUBLIC KEY-----'
    const pubKey = '-----BEGIN PUBLIC KEY-----\n\MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDqoIUktPNqNWe68ixwZPqke/wA\n\DD31wOumI3mDkYhSSSaDugPmxRgWptGblDDw2ygoR97XZyNY3Gn1RRO/91ATpAmN\n\Yd6C4ffdbxgrcrCbADPbzTG3xAccxRvemLlf9EZSeTD58NEE6wpAJIihaGPQw0Hc\n\MCqA4aYYY12Kq4edPwIDAQAB\n\-----END PUBLIC KEY-----'
    encryptor.setPublicKey(pubKey)
    //const sign = encryptor.encrypt(uncryptedText)
    const sign = encryptor.encryptLong(uncryptedText)
    config.headers['Content-Type'] = 'application/json'
    config.headers['rnumbers'] = randomNum
    config.headers['timestamp'] = timestamp
    config.headers['sign'] = sign
    //console.log("uncryptedText:" + uncryptedText)
    //console.log("sign:" + sign)
    //console.log("config.headers:\n" + config.headers)
    return config
  }, error => {
    return Promise.reject(error)
  })

// http response 拦截器
axios.interceptors.response.use(response => {
    return response
  }, error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
        // 返回 401 清除token信息并跳转到登录页面
        //store.commit(types.LOGOUT);
        router.currentRoute.path !== '/login' &&
        router.replace({
          path: '/login',
          query: { redirect: router.currentRoute.fullPath }
        })
      }
    }
    return Promise.reject(error)   // 返回接口返回的错误信息
  })


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.config.globalProperties.$axios = axios
app.config.globalProperties.$wssURL = wssURL
app.use(store).use(router).mount('#app')
