export default {
  name: 'LoginLogView',
  components: {},
  data() {
    return {
      currentPage: 1,
      pageNum: 20,
      total: 0,
      logList: [
        /*{
          id: 1,
          text: "登录ip:120.237.99.10 登录时间:2024-06-17 10:01:00"
        },
        {
        deviceId: 0,
        loginIp: "",
        loginLogId: 0,
        loginTime: "",
        loginType: 0,
        userId: "",
        userName: ""
        }*/
      ]
    };
  },
  mounted() {
    this.requestLoginLogList(this.currentPage, this.pageSize);
  },
  unmounted() {},
  methods: {
    handleCurrentChange(page) {
      this.currentPage = page;
      this.requestLoginLogList(this.currentPage, this.pageSize);
    },
    // 请求登录日志列表
    requestLoginLogList(pageNum, pageSize) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      this.$axios({
        method: 'post',
        url: '/loginLog/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize
        }
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取登录日志列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取登录日志列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.logList = root.rows;
        console.log("获取登录日志列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取登录日志列表失败, ' + error)
      }).finally(() => {});
    }
  }
};