import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-profile"
};
const _hoisted_2 = {
  class: "container-profile-info"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 8
    }), _createVNode(_component_el_col, {
      span: 8
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[1] || (_cache[1] = _createElementVNode("h3", {
        class: "title"
      }, "个人中心", -1)), _cache[2] || (_cache[2] = _createElementVNode("h5", {
        class: "subtitle"
      }, "个人资料", -1)), _cache[3] || (_cache[3] = _createElementVNode("p", {
        class: "username"
      }, "用户名：wanglei", -1)), _cache[4] || (_cache[4] = _createElementVNode("p", {
        class: "role"
      }, "角色：管理员", -1)), _cache[5] || (_cache[5] = _createElementVNode("p", {
        class: "email"
      }, "邮箱：wanglei@cookoo.com.cn", -1)), _cache[6] || (_cache[6] = _createElementVNode("h5", {
        class: "subtitle"
      }, "更多操作", -1)), _createVNode(_component_el_button, {
        type: "success",
        bg: "false",
        plain: "",
        id: "resetFormButton",
        onClick: $options.onClickChangePassword
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("修改密码")])),
        _: 1
      }, 8, ["onClick"])])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 8
    })]),
    _: 1
  })]);
}