import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "container-remote-mamager"
};
const _hoisted_2 = {
  class: "container-search-list"
};
const _hoisted_3 = {
  class: "container-filter"
};
const _hoisted_4 = {
  class: "container-list"
};
const _hoisted_5 = {
  class: "container-pagination"
};
const _hoisted_6 = {
  class: "container-request-list"
};
const _hoisted_7 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search = _resolveComponent("search");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_CarRequest = _resolveComponent("CarRequest");
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_cache[7] || (_cache[7] = _createElementVNode("div", {
    class: "div-space"
  }, null, -1)), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_input, {
    modelValue: $data.searchKeyword,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.searchKeyword = $event),
    class: "search",
    placeholder: "请输入名称或者ID"
  }, {
    prefix: _withCtx(() => [_createVNode(_component_el_icon, {
      class: "el-input__icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_search)]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_button, {
    type: "primary",
    dark: false,
    class: "button-search",
    onClick: $options.handleSearch
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("搜索")])),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_select, {
    modelValue: $data.typeValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.typeValue = $event),
    class: "select",
    placeholder: "车辆类型"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.typeFilterItems, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.id,
        label: item.typeName,
        value: item.typeValue
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_select, {
    modelValue: $data.statusValue,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.statusValue = $event),
    class: "select",
    placeholder: "状态"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.statusItems, item => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: item.value,
        label: item.label,
        value: item.value
      }, null, 8, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_button, {
    type: "primary",
    dark: false,
    class: "button-filter",
    onClick: $options.handleFilter
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("筛选")])),
    _: 1
  }, 8, ["onClick"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_table, {
    data: $data.tableData,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "deviceId",
      label: "ID",
      width: "180"
    }), _createVNode(_component_el_table_column, {
      prop: "deviceName",
      label: "车辆名称",
      width: "220"
    }), _createVNode(_component_el_table_column, {
      prop: "carNo",
      label: "车牌号",
      width: "220"
    }), _createVNode(_component_el_table_column, {
      prop: "typeName",
      label: "车辆类型",
      width: "220"
    }), _createVNode(_component_el_table_column, {
      prop: "operate",
      label: "状态",
      width: "220"
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "操作",
      width: "220"
    }, {
      default: _withCtx(scope => [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        size: "small",
        onClick: $event => $options.handleRemoteManagerDetail(scope.$index)
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("远程调度")])),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_pagination, {
    class: "pagination",
    layout: "prev, pager, next",
    total: $data.total,
    onCurrentChange: $options.handleCurrentChange
  }, null, 8, ["total", "onCurrentChange"])])]), _createElementVNode("div", _hoisted_6, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.requestItems, (item, index) => {
    return _openBlock(), _createBlock(_component_CarRequest, {
      class: "div-car",
      key: index,
      index: index,
      name: item.car,
      license_plate: "item.carNo",
      fault: item.fault,
      location: item.location,
      time: item.time,
      onCancelEvent: $options.handleCancel,
      onConfirmEvent: $options.handleConfirm
    }, null, 8, ["index", "name", "fault", "location", "time", "onCancelEvent", "onConfirmEvent"]);
  }), 128))]), _createVNode(_component_el_dialog, {
    modelValue: $data.deleteDialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.deleteDialogVisible = $event),
    title: "提示",
    width: "500",
    height: "100",
    "show-close": "false"
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
      onClick: $options.handleCancelDeleteOrder
    }, {
      default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("取消")])),
      _: 1
    }, 8, ["onClick"]), _createVNode(_component_el_button, {
      type: "primary",
      onClick: $options.handleConfirmDeleteOrder
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("确定")])),
      _: 1
    }, 8, ["onClick"])])]),
    default: _withCtx(() => [_createElementVNode("span", null, "确认删除订单" + _toDisplayString($data.deleteOrderId) + "？", 1)]),
    _: 1
  }, 8, ["modelValue"])]);
}