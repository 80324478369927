import "core-js/modules/es.array.push.js";

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'Header',
  data() {
    return {
      //username: 'wanglei',
      logoUrl: require('@/assets/header_logo.png')
    };
  },
  props: {
    title: String,
    username: {
      type: String,
      value: ''
    }
  },
  methods: {
    onClickUsername() {},
    onClickProfile() {
      this.$router.push('/profile');
    },
    onClickAbout() {
      this.$router.push('/about');
    },
    handleLogout() {
      console.log(localStorage.getItem('userInfo'));
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      const username = userInfo.userName;
      const password_md5 = userInfo.password;
      const sessionId = userInfo.sessionId;
      const agent = new https.Agent({
        rejectUnauthorized: false
      });
      this.$axios({
        httpsAgent: agent,
        method: 'post',
        url: '/user/logout',
        timeout: 10000,
        data: {
          'username': username,
          'password': password_md5,
          'sessionId': sessionId
        }
      }).then(response => {
        console.log(response);
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("退出登录失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("退出登录失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const data = root.data;
        ElMessage({
          message: '退出登录成功!',
          type: 'success'
        });
        let toUrl = '/login';
        this.$router.push(toUrl);
        localStorage.clear();
      }).catch(error => {
        console.log(error);
        ElMessage.error('退出登录失败, ' + error);
      }).finally(() => {});
    }
  }
};