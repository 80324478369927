import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-monitor-detail-background"
};
const _hoisted_2 = {
  class: "container-monitor-detail"
};
const _hoisted_3 = {
  class: "container-car-info"
};
const _hoisted_4 = {
  class: "container-car-detail"
};
const _hoisted_5 = {
  class: "div-detail"
};
const _hoisted_6 = {
  class: "div-videos-options"
};
const _hoisted_7 = {
  class: "div-options"
};
const _hoisted_8 = {
  class: "div-dashboard-map"
};
const _hoisted_9 = {
  class: "div-dashboard"
};
const _hoisted_10 = {
  class: "dashboard-speed"
};
const _hoisted_11 = {
  class: "div-speed"
};
const _hoisted_12 = {
  class: "div-rotate-speed"
};
const _hoisted_13 = {
  class: "container-gear"
};
const _hoisted_14 = ["src"];
const _hoisted_15 = ["src"];
const _hoisted_16 = {
  class: "container-control"
};
const _hoisted_17 = {
  class: "container-car-status"
};
const _hoisted_18 = {
  class: "container-footer"
};
const _hoisted_19 = {
  class: "div-today-info"
};
const _hoisted_20 = {
  class: "div-today-status"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item");
  const _component_el_descriptions = _resolveComponent("el-descriptions");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_button_group = _resolveComponent("el-button-group");
  const _component_SpeedPanel = _resolveComponent("SpeedPanel");
  const _component_RotateSpeedPanel = _resolveComponent("RotateSpeedPanel");
  const _component_Gear = _resolveComponent("Gear");
  const _component_el_text = _resolveComponent("el-text");
  const _component_el_divider = _resolveComponent("el-divider");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_descriptions, {
    class: "-top",
    title: "",
    column: 4,
    size: _ctx.size,
    border: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("div", {
        class: "cell-item"
      }, "车辆编号", -1)])),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString($data.deviceName), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[2] || (_cache[2] = [_createElementVNode("div", {
        class: "cell-item"
      }, "车辆VIN", -1)])),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString($data.vin), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[3] || (_cache[3] = [_createElementVNode("div", {
        class: "cell-item"
      }, "车牌号", -1)])),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString($data.carNo), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[4] || (_cache[4] = [_createElementVNode("div", {
        class: "cell-item"
      }, "运营人员", -1)])),
      default: _withCtx(() => [_cache[5] || (_cache[5] = _createTextVNode(" --- "))]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[6] || (_cache[6] = [_createElementVNode("div", {
        class: "cell-item"
      }, "远驾控制器SN", -1)])),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString($data.sn), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[7] || (_cache[7] = [_createElementVNode("div", {
        class: "cell-item"
      }, "运营类型", -1)])),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString($data.operate), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[8] || (_cache[8] = [_createElementVNode("div", {
        class: "cell-item"
      }, "车辆类型", -1)])),
      default: _withCtx(() => [_createTextVNode(" " + _toDisplayString($data.typeName), 1)]),
      _: 1
    }), _createVNode(_component_el_descriptions_item, null, {
      label: _withCtx(() => _cache[9] || (_cache[9] = [_createElementVNode("div", {
        class: "cell-item"
      }, "远程驾驶舱/驾驶员", -1)])),
      default: _withCtx(() => [_cache[10] || (_cache[10] = _createTextVNode(" ---/--- "))]),
      _: 1
    })]),
    _: 1
  }, 8, ["size"])]), _createElementVNode("div", _hoisted_4, [_cache[16] || (_cache[16] = _createElementVNode("div", {
    class: "div-time"
  }, null, -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button_group, {
    class: "ml-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      type: "info",
      style: _normalizeStyle($data.stylesheet_remote)
    }, {
      default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("远程驾驶")])),
      _: 1
    }, 8, ["style"]), _createVNode(_component_el_button, {
      type: "info",
      style: _normalizeStyle($data.stylesheet_auto)
    }, {
      default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("域控自动驾驶")])),
      _: 1
    }, 8, ["style"]), _createVNode(_component_el_button, {
      type: "info",
      style: _normalizeStyle($data.stylesheet_manual)
    }, {
      default: _withCtx(() => _cache[13] || (_cache[13] = [_createTextVNode("人工驾驶")])),
      _: 1
    }, 8, ["style"])]),
    _: 1
  })]), _cache[14] || (_cache[14] = _createElementVNode("div", {
    class: "div-videos"
  }, [_createElementVNode("div", {
    class: "div-front-background"
  }, [_createElementVNode("iframe", {
    class: "iframe-video",
    id: "iframe-front",
    src: "",
    style: {
      "background": "#0f0e0e"
    },
    allowfullscreen: "true",
    webkitallowfullscreen: "true",
    mozallowfullscreen: "true"
  })]), _createElementVNode("div", {
    class: "div-back-background"
  }, [_createElementVNode("iframe", {
    class: "iframe-video",
    id: "iframe-back",
    src: "",
    style: {
      "background": "#0f0e0e"
    }
  })]), _createElementVNode("div", {
    class: "div-left-background"
  }, [_createElementVNode("iframe", {
    class: "iframe-video",
    id: "iframe-left",
    src: "",
    style: {
      "background": "#0f0e0e"
    }
  })]), _createElementVNode("div", {
    class: "div-right-background"
  }, [_createElementVNode("iframe", {
    class: "iframe-video",
    id: "iframe-right",
    src: "",
    style: {
      "background": "#0f0e0e"
    }
  })]), _createElementVNode("div", {
    class: "div-inside-background"
  }, [_createElementVNode("iframe", {
    class: "iframe-video",
    id: "iframe-inside",
    src: "",
    style: {
      "background": "#0f0e0e"
    }
  })]), _createElementVNode("div", {
    class: "div-avm-background"
  }, [_createElementVNode("iframe", {
    class: "iframe-video",
    id: "iframe-avm",
    src: "",
    style: {
      "background": "#0f0e0e"
    }
  })])], -1))]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, [_createVNode(_component_SpeedPanel, {
    style: {
      "width": "100%",
      "height": "100%"
    },
    speed: $data.speed
  }, null, 8, ["speed"])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_RotateSpeedPanel, {
    style: {
      "width": "100%",
      "height": "100%"
    },
    rpm: $data.rpm
  }, null, 8, ["rpm"])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("img", {
    class: "turn-left-lamp",
    src: $data.turnLeftSrc
  }, null, 8, _hoisted_14), _createVNode(_component_Gear, {
    class: "components-gear",
    gearValue: $data.gear
  }, null, 8, ["gearValue"]), _createElementVNode("img", {
    class: "turn-right-lamp",
    src: $data.turnRightSrc
  }, null, 8, _hoisted_15)]), _createElementVNode("div", _hoisted_16, [_createVNode(_component_el_text, {
    class: "control-wheel-angle"
  }, {
    default: _withCtx(() => [_createTextVNode("方向盘 " + _toDisplayString($data.steer) + "°", 1)]),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "control-throttle"
  }, {
    default: _withCtx(() => [_createTextVNode("油门踏板 " + _toDisplayString($data.throttle) + "%", 1)]),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "control-brake"
  }, {
    default: _withCtx(() => [_createTextVNode("刹车踏板 " + _toDisplayString($data.brake) + "%", 1)]),
    _: 1
  })]), _createElementVNode("div", _hoisted_17, [_createVNode(_component_el_text, {
    class: "car-status-mode"
  }, {
    default: _withCtx(() => [_createTextVNode("驾驶模式 " + _toDisplayString($data.mode), 1)]),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "car-status-soc"
  }, {
    default: _withCtx(() => [_createTextVNode("车辆电量 " + _toDisplayString($data.battery) + "%", 1)]),
    _: 1
  })])]), _cache[15] || (_cache[15] = _createElementVNode("div", {
    class: "div-map",
    id: "map-baidu"
  }, "地图 ", -1))])])]), _createElementVNode("div", _hoisted_18, [_createElementVNode("div", _hoisted_19, [_cache[18] || (_cache[18] = _createElementVNode("div", {
    class: "div-today-title"
  }, "今日运营信息", -1)), _createElementVNode("div", _hoisted_20, [_createElementVNode("span", null, "平均时速：" + _toDisplayString($data.avgSpeed) + "km/h", 1), _createVNode(_component_el_divider, {
    direction: "vertical",
    style: {
      "background": "#9F9F9F",
      "height": "12px"
    }
  }), _createElementVNode("span", null, "最高时速：" + _toDisplayString($data.maxSpeed) + "km/h", 1), _createVNode(_component_el_divider, {
    direction: "vertical",
    style: {
      "background": "#9F9F9F",
      "height": "12px"
    }
  }), _createElementVNode("span", null, "最低时速：" + _toDisplayString($data.minSpeed) + "km/h", 1), _createVNode(_component_el_divider, {
    direction: "vertical",
    style: {
      "background": "#9F9F9F",
      "height": "12px"
    }
  }), _createElementVNode("span", null, "行驶里程：" + _toDisplayString($data.distance) + "km", 1), _createVNode(_component_el_divider, {
    direction: "vertical",
    style: {
      "background": "#9F9F9F",
      "height": "12px"
    }
  }), _createElementVNode("span", null, "运营总时长：" + _toDisplayString($data.sunRunTime / 3600.0) + "小时", 1), _createElementVNode("button", {
    class: "btn-order-num",
    style: {
      "color": "#14A97C",
      "border-radius": "0px",
      "background": "white",
      "border": "1px solid #14A97C",
      "font-size": "12px",
      "margin-left": "40px",
      "width": "120px",
      "height": "30px"
    },
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleSuccessOrderNum && $options.handleSuccessOrderNum(...args))
  }, "当天完成订单数"), _cache[17] || (_cache[17] = _createElementVNode("button", {
    class: "div-fault-num",
    style: {
      "color": "#F1923A",
      "border-radius": "0px",
      "background": "white",
      "border": "1px solid #F1923A",
      "font-size": "12px",
      "margin-left": "20px",
      "width": "80px",
      "height": "30px"
    }
  }, "故障数", -1))])]), _cache[19] || (_cache[19] = _createElementVNode("div", {
    class: "div-remote-info"
  }, null, -1))])])]);
}