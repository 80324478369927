import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-change-password"
};
const _hoisted_2 = {
  class: "div-change-password"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 10
    }), _createVNode(_component_el_col, {
      span: 4
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_cache[4] || (_cache[4] = _createElementVNode("h3", {
        class: "title"
      }, "修改密码", -1)), _createVNode(_component_el_form, {
        rules: _ctx.rules,
        ref: "changePasswordForm",
        model: _ctx.loginForm,
        class: "change-password-form"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          prop: "passwordOriginal"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            type: "password",
            modelValue: $data.changePasswordForm.passwordOriginal,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.changePasswordForm.passwordOriginal = $event),
            placeholder: "请输入原密码",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "passwordNew"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            type: "password",
            modelValue: $data.changePasswordForm.passwordNew,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.changePasswordForm.passwordNew = $event),
            placeholder: "请输入新密码",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_form_item, {
          prop: "passwordConfirm"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            type: "password",
            modelValue: $data.changePasswordForm.passwordConfirm,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.changePasswordForm.passwordConfirm = $event),
            placeholder: "请再次输入新密码",
            "show-password": ""
          }, null, 8, ["modelValue"])]),
          _: 1
        }), _createVNode(_component_el_button, {
          class: "change-password-button",
          type: "primary",
          onClick: $options.submitChangePassword
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("修改密码")])),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      }, 8, ["rules", "model"])])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 10
    })]),
    _: 1
  })]);
}