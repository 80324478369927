import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "toolbar"
};
const _hoisted_2 = {
  class: "div-title"
};
const _hoisted_3 = {
  class: "span-title"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_link = _resolveComponent("el-link");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    type: "flex",
    justify: "center"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 14,
      class: "col-title"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_image, {
        class: "title-logo",
        src: $data.logoUrl,
        fit: "contain"
      }, null, 8, ["src"]), _createElementVNode("span", _hoisted_3, _toDisplayString($props.title), 1)])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 5,
      class: "col-space"
    }), _createVNode(_component_el_col, {
      span: 5,
      class: "col-options"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_link, {
        type: "info",
        onClick: $options.onClickUsername
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString($props.username), 1)]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_link, {
        type: "info",
        onClick: $options.handleLogout
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("退出登录")])),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_link, {
        type: "info",
        onClick: $options.onClickProfile
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [_createTextVNode("个人中心")])),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_link, {
        type: "info",
        onClick: $options.onClickAbout
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("关于")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  })]);
}