import "core-js/modules/es.array.push.js";
import CabinCell from '@/components/CabinCell.vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  components: {
    CabinCell
  },
  data() {
    return {
      MAX_RECONNECT_TRIES: 5,
      reconnectTries: 0,
      websocket: null,
      timer: null,
      carStatusTimer: null,
      wsUrl: 'wss://192.168.19.15:8899',
      map: null,
      marker: null,
      info: null,
      cabinList: [],
      total: 0,
      selectedCabinId: null,
      deviceName: null,
      deviceId: null,
      sn: null,
      vechileGps: null,
      vechileAddress: null,
      operate: null,
      typeName: null,
      carNo: null,
      orderId: null,
      // 车辆状态实时数据
      rpm: 0,
      battery: '0%',
      brake: 0,
      gear: 'N',
      mode: '自动驾驶',
      speed: 0,
      steer: 0,
      throttle: 0,
      turnLight: '关闭',
      // 相机状态
      frontCameraStatus: '--',
      backCameraStatus: '--',
      leftCameraStatus: '--',
      rightCameraStatus: '--',
      insideCameraStatus: '--',
      avmCameraStatus: '--'
    };
  },
  methods: {
    handleBack() {
      this.$router.back();
    },
    handleConfirmCabin(deviceId) {
      for (var item of this.cabinList) {
        if (item.deviceId === deviceId) {
          item['selected'] = !item.selected;
          if (item.selected === true) {
            this.selectedCabinId = item.deviceId;
          } else {
            this.selectedCabinId = null;
          }
        } else {
          item['selected'] = false;
        }
      }
    },
    handleConfirmOrder() {
      //this.vechileAddress = '天津市东丽区华明大道22号8-1'
      if (this.vechileGps === null || this.vechileGps === undefined || this.vechileGps.trim() === '') {
        this.vechileGps = '117.372458,39.174445';
      }
      this.requestCreateOrder(this.orderId, this.selectedCabinId, '100', this.vechileAddress, this.deviceId, this.vechileGps, 3005);
    },
    handleCancelOrder() {
      this.$router.back();
    },
    initBaiduMap(lon, lat) {
      var map = new BMapGL.Map("map-baidu");
      var point = new BMapGL.Point(117.372458, 39.174445);
      map.centerAndZoom(point, 20);
      this.map = map;
    },
    addMarker(lon, lat) {
      //console.log('====> lon:' + lon + ', lat:' + lat)
      if (this.marker != null) {
        this.map.removeOverlay(this.marker);
      }
      var point = new BMapGL.Point(lon, lat);
      this.map.centerAndZoom(point, 20);
      var myIcon = new BMapGL.Icon("./map_car.png", new BMapGL.Size(52, 52), {
        // 指定定位位置。  
        // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
        // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
        // 图标中央下端的尖角位置。   
        anchor: new BMapGL.Size(26, 52)
        // 设置图片偏移。  
        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
        // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
        // imageOffset: new BMapGL.Size(0, 0)  // 设置图片偏移   
      });
      // 创建Marker标注，使用小车图标
      var marker = new BMapGL.Marker(point, {
        icon: myIcon
      });

      // marker.addEventListener("click", function() {   
      //   alert("您点击了标注");   
      // });
      this.map.addOverlay(marker); // 将标注添加到地图中
      this.marker = marker;
    },
    initWebsocket() {
      this.isReconnect = true;
      this.websocket = new WebSocket(this.wsUrl);
      // this.websocket.onmessage = function(event) {
      //   console.log(`收到消息：${event.data}`)
      // }
      this.websocket.onopen = this.websocketOpen;
      this.websocket.onerror = this.websocketError;
      this.websocket.onmessage = this.websocketOnMessage;
      this.websocket.onclose = this.closeWebsocket;
    },
    destoryWebsocket() {
      this.isReconnect = false;
      this.websocket.close();
    },
    websocketOpen() {
      console.log('websocket onOpen', this.websocket.readyState);
      this.reconnectTries = 0;
      // WebSocket.CONNECTING  0   连接正在进行，但还未建立
      // WebSocket.OPEN        1   连接已经建立，消息可以在客户端和服务端进行传递
      // WebSocket.CLOSING     2   连接正在进行关闭握手
      // WebSocket.CLOSED      3   连接已经关闭，不能打开
      if (this.websocket.readyState === WebSocket.OPEN) {
        console.log('websocket已连接！');
        this.startHeartbeat();
      }
    },
    websocketError() {
      console.log('websocket onError', this.websocket.readyState);
    },
    websocketOnMessage(event) {
      //console.log(`收到消息：${event.data}`)
      let root = JSON.parse(event.data);
      const op = root['op'];
      const dev = root['dev'];
      //console.log('====> op: ' + op)
      if (op === 'GET_CAR_CAMERA_STATE_RPY' && dev === this.deviceName) {
        // 相机状态
        const data = root['data'];
        const front = root['front'];
        if (front === 1) {
          this.frontCameraStatus = '正常';
        } else {
          this.frontCameraStatus = '故障';
        }
        const back = root['back'];
        if (back === 1) {
          this.backCameraStatus = '正常';
        } else {
          this.backCameraStatus = '故障';
        }
        const left = root['left'];
        if (left === 1) {
          this.leftCameraStatus = '正常';
        } else {
          this.leftCameraStatus = '故障';
        }
        const right = root['right'];
        if (right === 1) {
          this.rightCameraStatus = '正常';
        } else {
          this.rightCameraStatus = '故障';
        }
        const inside = root['inside'];
        if (inside === 1) {
          this.insideCameraStatus = '正常';
        } else {
          this.insideCameraStatus = '故障';
        }
        const avm = root['avm'];
        if (inside === 1) {
          this.avmCameraStatus = '正常';
        } else {
          this.avmCameraStatus = '故障';
        }
      } else if (op === 'GET_CAR_STATE_RPY' && dev === this.deviceName) {
        // 车辆状态实时数据
        const data = root['data'];
        if (data != null) {
          this.rpm = data['RPM'];
          this.battery = data['battery'] + '%';
          this.brake = data['break'];
          this.gear = data['gear'];
          const mode = data['mode'];
          if (mode === 1) {
            this.mode = '远程驾驶';
          } else if (mode === 2) {
            this.mode = '人工驾驶';
          } else {
            this.mode = '域控自动驾驶';
          }
          this.speed = data['speed'];
          this.steer = data['steer'];
          this.throttle = data['throttle'];
          const turn_light = data['turn_light'];
          if (turn_light === 1) {
            this.turnLight = '左转向开';
          } else if (turn_light === 2) {
            this.turnLight = '右转向开';
          } else if (turn_light === 3) {
            this.turnLight = '双闪开';
          } else {
            this.turnLight = '关';
          }
          const lon = data['lon'];
          const lat = data['lat'];
          this.addMarker(lon, lat);
        }
      } else if (op === 'GET_CABIN_STATE_RPY') {
        // 舱端设备状态
        const data = root['data'];
        const state = data['state'];
        for (var cabin of this.cabinList) {
          if (cabin.deviceName === dev) {
            if (state === 1) {
              cabin['state'] = '未登录';
            } else if (state === 2) {
              cabin['state'] = '空闲';
            } else if (state === 3) {
              cabin['state'] = '监控';
            } else if (state === 4) {
              cabin['state'] = '多车监控';
            } else if (state === 5) {
              cabin['state'] = '控车中';
            } else {
              cabin['state'] = '离线';
            }
            cabin['driverId'] = data['driverId'];
            cabin['address'] = data['address'];
          }
        }
      }
    },
    closeWebsocket() {
      console.log('websocket onClose', this.websocket.readyState);
      this.stopHeartbeat();
      this.websocket.close();
      this.reconnect();
    },
    sendMessage(type, dev) {
      if (this.websocket.readyState === WebSocket.OPEN) {
        const ts = new Date().getTime();
        this.websocket.send(JSON.stringify({
          op: type,
          dev: dev,
          cmdid: ts
        }));
      }
    },
    reconnect() {
      if (this.reconnectTries < this.MAX_RECONNECT_TRIES) {
        this.reconnectTries++;
        setTimeout(() => {
          this.initWebsocket();
        }, 2000);
      } else {
        console.error('达到最大重连次数，停止重连');
      }
    },
    startHeartbeat() {
      this.timer = setInterval(() => {
        const ts = new Date().getTime();
        if (this.websocket != null) {
          this.websocket.send(JSON.stringify({
            type: 'heartbeat',
            timestamp: ts
          }));
        }
        //console.log('==> timerout:' + ts)
      }, 15 * 1000); // 每15s执行一次
    },
    stopHeartbeat() {
      if (this.timer != null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    startFetchCarStatus() {
      this.carStatusTimer = setInterval(() => {
        this.sendMessage('GET_CAR_STATE', this.deviceName);
        this.sendMessage('GET_CAR_CAMERA_STATE', this.deviceName);
        for (var cabin of this.cabinList) {
          this.sendMessage('GET_CABIN_STATE', cabin.deviceName);
        }
      }, 1000); // 每1s执行一次
    },
    stopFetchCarStatus() {
      if (this.carStatusTimer != null) {
        clearInterval(this.carStatusTimer);
        this.carStatusTimer = null;
      }
    },
    // 请求舱端设备列表
    requestCabinDeviceList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      this.$axios({
        method: 'post',
        url: '/device/queryCabinByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'createdType': 0,
          'operateState': '',
          'type': ''
        }
      }).then(response => {
        this.info = response;
        //console.log(JSON.stringify(response))
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取舱端设备列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取舱端设备列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        //this.cabinList = root.rows
        this.cabinList = [];
        for (var row of root.rows) {
          row['selected'] = false;
          row['state'] = '--';
          row['driverId'] = '--';
          row['address'] = '--';
          this.cabinList.push(row);
        }
        console.log("获取舱端设备列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取舱端设备列表失败, ' + error)
      }).finally(() => {});
    },
    // 请求创建调度订单
    requestCreateOrder(orderId, cabinId, driverId, vechileAddress, vechileDeviceId, vechileGps, faultType) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (userId === null || userId === undefined || userId.trim() === '') {
        ElMessage.error('用户ID为空!');
        return;
      }
      if (vechileDeviceId === null || vechileDeviceId === undefined || vechileDeviceId.trim() === '') {
        ElMessage.info('车辆ID为空!');
        return;
      }
      if (vechileAddress === null || vechileAddress === undefined || vechileAddress.trim() === '') {
        ElMessage.info('车辆地址为空!');
        return;
      }
      if (vechileGps === null || vechileGps === undefined || vechileGps.trim() === '') {
        ElMessage.info('车辆GPS为空!');
        return;
      }
      if (cabinId === null || cabinId === undefined) {
        ElMessage.info('请选择驾驶舱!');
        return;
      }
      this.$axios({
        method: 'post',
        url: '/order/add',
        timeout: 10000,
        //data: {orderId: orderId, controlDeviceId: cabinId, created: userId, driverId: driverId, vechileAddress: vechileAddress, vechileDeviceId: vechileDeviceId, vechileGps: vechileGps}
        data: {
          controlDeviceId: cabinId,
          created: userId,
          driverId: driverId,
          vechileAddress: vechileAddress,
          vechileDeviceId: vechileDeviceId,
          vechileGps: vechileGps
        }
      }).then(response => {
        this.info = response;
        //console.log(JSON.stringify(response))
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("创建调度订单失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("创建调度订单失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.requestConfirmOrder(orderId, cabinId, vechileAddress, vechileDeviceId, vechileGps, faultType);
        //ElMessage.success(root.msg)
        console.log('创建调度订单:', root.msg);
      }).catch(error => {
        console.log(error);
        this.error = error;
        ElMessage.error('创建调度订单失败, ' + error);
      }).finally(() => {});
    },
    requestConfirmOrder(orderId, cabinId, vechileAddress, vechileDeviceId, vechileGps, faultType) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (userId === null || userId === undefined || userId.trim() === '') {
        ElMessage.error('用户ID为空!');
        return;
      }
      if (vechileDeviceId === null || vechileDeviceId === undefined || vechileDeviceId.trim() === '') {
        ElMessage.info('车辆ID为空!');
        return;
      }
      if (vechileAddress === null || vechileAddress === undefined || vechileAddress.trim() === '') {
        ElMessage.info('车辆地址为空!');
        return;
      }
      if (vechileGps === null || vechileGps === undefined || vechileGps.trim() === '') {
        ElMessage.info('车辆GPS为空!');
        return;
      }
      if (cabinId === null || cabinId === undefined) {
        ElMessage.info('请选择驾驶舱!');
        return;
      }
      this.$axios({
        method: 'post',
        url: '/control/setOrder',
        timeout: 10000,
        //data: {orderId: orderId, controlDeviceId: cabinId, created: userId, driverId: driverId, vechileAddress: vechileAddress, vechileDeviceId: vechileDeviceId, vechileGps: vechileGps}
        //data: {controlDeviceId: cabinId, created: userId, driverId: driverId, vechileAddress: vechileAddress, vechileDeviceId: vechileDeviceId, vechileGps: vechileGps}
        data: {
          controlDeviceId: cabinId,
          created: userId,
          faultType: faultType,
          orderId: orderId,
          vechileAddress: vechileAddress,
          vechileDeviceId: vechileDeviceId,
          vechileGps: vechileGps
        }
      }).then(response => {
        this.info = response;
        //console.log(JSON.stringify(response))
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("调度失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("调度失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        ElMessage.success(root.msg);
        console.log('调度:', root.msg);
      }).catch(error => {
        console.log(error);
        this.error = error;
        ElMessage.error('调度失败, ' + error);
      }).finally(() => {});
    }
  },
  mounted() {
    this.wsUrl = this.$wssURL;
    //         fetch('config.json')
    // .then(response => response.json())
    // .then(config => {
    //     console.log(config);
    //     this.wsUrl = config.wss
    // })
    // .catch(error => console.error('Error loading config:', error));
    this.deviceName = this.$route.query.deviceName;
    this.deviceId = this.$route.query.deviceId;
    this.sn = this.$route.query.sn, this.vechileGps = this.$route.query.vechileGps, this.vechileAddress = this.$route.query.vechileAddress, this.operate = this.$route.query.operate, this.typeName = this.$route.query.typeName, this.carNo = this.$route.query.carNo;
    this.orderId = this.$route.query.orderId;
    var lng = 117.372458;
    var lat = 39.174445;
    if (this.vechileGps != null && this.vechileGps != undefined && this.vechileGps.trim() != '') {
      var gps = this.vechileGps.split(',');
      if (gps.length > 1) {
        lng = gps[0];
        lat = gps[1];
      }
    }
    if (this.vechileAddress === null || this.vechileAddress === undefined || this.vechileAddress.trim() === '') {
      // 创建地理编码实例      
      var myGeo = new BMapGL.Geocoder();
      // 根据坐标得到地址描述 
      const self = this;
      myGeo.getLocation(new BMapGL.Point(lng, lat), function (result) {
        if (result) {
          //alert(result.address); 
          self.vechileAddress = result.address;
        } else {
          self.vechileAddress = this.vechileGps;
        }
      });
    }
    this.initBaiduMap(lng, lat);
    this.requestCabinDeviceList();
    this.initWebsocket();
    this.startFetchCarStatus();
  },
  unmounted() {
    this.destoryWebsocket();
    this.stopFetchCarStatus();
  }
};