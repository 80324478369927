import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/turn_left_on.png';
import _imports_1 from '@/assets/turn_right_off.png';
const _hoisted_1 = {
  class: "container-monitor-detail"
};
const _hoisted_2 = {
  class: "div-header"
};
const _hoisted_3 = {
  class: "div-body"
};
const _hoisted_4 = {
  class: "container-carinfo"
};
const _hoisted_5 = {
  class: "container-dashboard"
};
const _hoisted_6 = {
  class: "dashboard-speed"
};
const _hoisted_7 = {
  class: "div-speed"
};
const _hoisted_8 = {
  class: "div-rotate-speed"
};
const _hoisted_9 = {
  class: "container-gear"
};
const _hoisted_10 = {
  class: "container-control"
};
const _hoisted_11 = {
  class: "container-car-status"
};
const _hoisted_12 = {
  class: "container-operations"
};
const _hoisted_13 = {
  class: "div-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_text = _resolveComponent("el-text");
  const _component_SpeedPanel = _resolveComponent("SpeedPanel");
  const _component_RotateSpeedPanel = _resolveComponent("RotateSpeedPanel");
  const _component_Gear = _resolveComponent("Gear");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_button_group = _resolveComponent("el-button-group");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_text, {
    class: "title",
    size: "large"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("车端远程监控详情")])),
    _: 1
  })]), _createElementVNode("div", _hoisted_3, [_cache[13] || (_cache[13] = _createElementVNode("div", {
    class: "container-map"
  }, [_createElementVNode("div", {
    class: "map",
    id: "map-baidu"
  })], -1)), _createElementVNode("div", _hoisted_4, [_cache[12] || (_cache[12] = _createElementVNode("div", {
    class: "container-video"
  }, [_createElementVNode("video", {
    autoplay: "",
    controls: "",
    muted: "",
    width: "100%",
    height: "100%",
    id: "video-front"
  })], -1)), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createVNode(_component_SpeedPanel, {
    style: {
      "width": "100%",
      "height": "100%"
    }
  })]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_RotateSpeedPanel, {
    style: {
      "width": "100%",
      "height": "100%"
    }
  })])]), _createElementVNode("div", _hoisted_9, [_cache[1] || (_cache[1] = _createElementVNode("img", {
    class: "turn-left-lamp",
    src: _imports_0
  }, null, -1)), _createVNode(_component_Gear, {
    class: "components-gear"
  }), _cache[2] || (_cache[2] = _createElementVNode("img", {
    class: "turn-right-lamp",
    src: _imports_1
  }, null, -1))]), _createElementVNode("div", _hoisted_10, [_createVNode(_component_el_text, {
    class: "control-wheel-angle"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("方向盘 左5°")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "control-throttle"
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("油门踏板 30%")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "control-brake"
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("刹车踏板 0%")])),
    _: 1
  })]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_text, {
    class: "car-status-mode"
  }, {
    default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("驾驶模式 自动驾驶")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "car-status-soc"
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("车辆SOC 35%")])),
    _: 1
  })])]), _createElementVNode("div", _hoisted_12, [_createVNode(_component_el_button, {
    type: "primary",
    class: "button-play",
    onClick: $options.handlePlay
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString($data.playButtonTitle), 1)]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_text, {
    class: "text-play-speed",
    size: "small"
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("播放速度：")])),
    _: 1
  }), _createVNode(_component_el_button_group, {
    class: "ml-4"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary"
    }, {
      default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("1x")])),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary"
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("2x")])),
      _: 1
    }), _createVNode(_component_el_button, {
      type: "primary"
    }, {
      default: _withCtx(() => _cache[11] || (_cache[11] = [_createTextVNode("5x")])),
      _: 1
    })]),
    _: 1
  })])])]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_el_text, {
    class: "mx-1 text-distance"
  }, {
    default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("里程：100km")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-avg-speed"
  }, {
    default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("平均时速：36km/h")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-duration"
  }, {
    default: _withCtx(() => _cache[16] || (_cache[16] = [_createTextVNode("累计行驶时长：32h")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-max-speed"
  }, {
    default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("最高时速：60km/h")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-order-num"
  }, {
    default: _withCtx(() => _cache[18] || (_cache[18] = [_createTextVNode("远程驾驶订单个数：15个")])),
    _: 1
  })])]);
}