import PlayImage from '@/assets/play.png';
import AudioImage from '@/assets/audio.png';
export default {
  components: {
    PlayImage,
    AudioImage
  },
  data() {
    return {
      playImage: PlayImage,
      audioImage: AudioImage
    };
  },
  props: {
    startTime: String,
    filepath: String,
    locationType: Number,
    fileType: Number
  }
};