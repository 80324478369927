import "core-js/modules/es.array.push.js";
export default {
  name: 'CarMonitorQueryView',
  components: {},
  data() {
    return {
      centerDialogVisible: false,
      addDialog: false,
      loading: false,
      formLabelWidth: 80,
      form: {
        code: '',
        vin: '',
        type: '',
        status: '',
        sn: '',
        license_plate: ''
      },
      statusValue: '',
      statusItems: [{
        value: '运营',
        label: '运营'
      }, {
        value: '非运营',
        label: '非运营'
      }],
      typeValue: '',
      typeItems: [{
        value: '清扫车（小）',
        label: '清扫车（小）'
      }, {
        value: '清扫车（中）',
        label: '清扫车（中）'
      }, {
        value: '巡逻车',
        label: '巡逻车'
      }, {
        value: '教学车',
        label: '教学车'
      }, {
        value: '小巴',
        label: '小巴'
      }, {
        value: '矿卡',
        label: '矿卡'
      }, {
        value: '物流车',
        label: '物流车'
      }, {
        value: '集卡',
        label: '集卡'
      }],
      tableData: [{
        id: '001',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '002',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '003',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '004',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '005',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '006',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '007',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '008',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }, {
        id: '009',
        cabin_id: 'CABIN001',
        duration: '12h',
        duration_total: '88h',
        distance_total: '156km',
        max_speed: '36km/h',
        driver: '司机01',
        order_num: '55'
      }]
    };
  },
  methods: {
    handleCurrentChange(page) {
      console.log(page);
    },
    handleToDetail(index) {
      console.log(index);
      this.$router.push('/carmonitordetail');
    }
  }
};