export default {
  name: 'CabinCell',
  props: {
    deviceId: String,
    name: String,
    driver: String,
    location: String,
    sn: String,
    type: String,
    state: String,
    driverId: String,
    address: String,
    selected: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    backgroundColor() {
      return this.selected ? '#14A97C' : '#E6E6E6';
    }
  },
  methods: {
    handleClickItem() {
      this.$emit('confirmEvent', this.deviceId);
    }
  }
};