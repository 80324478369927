import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-car-request"
};
const _hoisted_2 = {
  class: "div-car-info"
};
const _hoisted_3 = {
  class: "container-texts"
};
const _hoisted_4 = {
  class: "car-name"
};
const _hoisted_5 = {
  class: "car-license-plate"
};
const _hoisted_6 = {
  class: "car-fault"
};
const _hoisted_7 = {
  class: "car-location"
};
const _hoisted_8 = {
  class: "car-time"
};
const _hoisted_9 = {
  class: "container-buttons"
};
const _hoisted_10 = {
  class: "container-num"
};
const _hoisted_11 = {
  class: "div-num",
  style: {
    "width": "37px",
    "height": "26px",
    "background-color": "#D1E9E2",
    "line-height": "26px",
    "text-align": "center",
    "font-size": "12px",
    "font-family": "Microsoft YaHei, Microsoft YaHei"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "div-request-title"
  }, "车辆请求接管", -1)), _createElementVNode("div", _hoisted_4, "车辆名称：" + _toDisplayString($props.name), 1), _createElementVNode("div", _hoisted_5, "车牌号：" + _toDisplayString($props.license_plate), 1), _createElementVNode("div", _hoisted_6, "车辆故障：" + _toDisplayString($props.fault), 1), _createElementVNode("div", _hoisted_7, "故障地点：" + _toDisplayString($props.location), 1), _createElementVNode("div", _hoisted_8, "时间：" + _toDisplayString($props.time), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("button", {
    style: {
      "width": "68px",
      "height": "30px",
      "border-radius": "0px",
      "border-width": "0px",
      "font-size": "14px",
      "background-color": "#DDDDDD"
    },
    onClick: _cache[0] || (_cache[0] = (...args) => $options.handleCancel && $options.handleCancel(...args))
  }, "取消"), _createElementVNode("button", {
    style: {
      "width": "68px",
      "height": "30px",
      "border-radius": "0px",
      "border-width": "0px",
      "font-size": "14px",
      "color": "white",
      "background-color": "#14A97C",
      "margin-left": "12px"
    },
    onClick: _cache[1] || (_cache[1] = (...args) => $options.handleConfirm && $options.handleConfirm(...args))
  }, "调度")])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString($props.index), 1)])]);
}