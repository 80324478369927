import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@/assets/back_white.png';
const _hoisted_1 = {
  class: "container-remote-manager-detail"
};
const _hoisted_2 = {
  class: "container-back"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("button", {
    class: "button-back",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.handleBack && _ctx.handleBack(...args))
  }, _cache[1] || (_cache[1] = [_createElementVNode("img", {
    class: "button-img-back",
    src: _imports_0
  }, null, -1), _createTextVNode(" 返回 ")]))]), _cache[2] || (_cache[2] = _createElementVNode("div", {
    class: "container-car-info"
  }, null, -1))]);
}