import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-car-monitor-query"
};
const _hoisted_2 = {
  class: "div-header"
};
const _hoisted_3 = {
  class: "div-body"
};
const _hoisted_4 = {
  class: "container-filter"
};
const _hoisted_5 = {
  class: "container-total"
};
const _hoisted_6 = {
  class: "container-list"
};
const _hoisted_7 = {
  class: "container-pagination"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_text = _resolveComponent("el-text");
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_button = _resolveComponent("el-button");
  const _component_search = _resolveComponent("search");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_text, {
    class: "title",
    size: "large"
  }, {
    default: _withCtx(() => _cache[2] || (_cache[2] = [_createTextVNode("驾舱历史信息查询")])),
    _: 1
  })]), _createElementVNode("div", _hoisted_3, [_cache[11] || (_cache[11] = _createElementVNode("div", {
    class: "div-space"
  }, null, -1)), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_date_picker, {
    class: "date-picker-query",
    modelValue: _ctx.value2,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.value2 = $event),
    type: "daterange",
    "unlink-panels": "",
    "range-separator": "至",
    "start-placeholder": "Start date",
    "end-placeholder": "End date",
    shortcuts: _ctx.shortcuts,
    size: _ctx.size
  }, null, 8, ["modelValue", "shortcuts", "size"]), _createVNode(_component_el_button, {
    type: "primary",
    dark: false,
    class: "button-query"
  }, {
    default: _withCtx(() => _cache[3] || (_cache[3] = [_createTextVNode("查询")])),
    _: 1
  }), _createVNode(_component_el_input, {
    modelValue: _ctx.input4,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.input4 = $event),
    class: "input-search",
    placeholder: "请输入搜索内容"
  }, {
    prefix: _withCtx(() => [_createVNode(_component_el_icon, {
      class: "el-input__icon"
    }, {
      default: _withCtx(() => [_createVNode(_component_search)]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_button, {
    type: "primary",
    dark: false,
    class: "button-search"
  }, {
    default: _withCtx(() => _cache[4] || (_cache[4] = [_createTextVNode("搜索")])),
    _: 1
  })]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_el_text, {
    class: "mx-1 text-distance"
  }, {
    default: _withCtx(() => _cache[5] || (_cache[5] = [_createTextVNode("里程：100km")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-avg-speed"
  }, {
    default: _withCtx(() => _cache[6] || (_cache[6] = [_createTextVNode("平均时速：36km/h")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-duration"
  }, {
    default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("累计行驶时长：32h")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-max-speed"
  }, {
    default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("最高时速：60km/h")])),
    _: 1
  }), _createVNode(_component_el_text, {
    class: "mx-1 text-order-num"
  }, {
    default: _withCtx(() => _cache[9] || (_cache[9] = [_createTextVNode("远程驾驶订单个数：15个")])),
    _: 1
  })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_table, {
    data: $data.tableData,
    style: {
      "width": "100%"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "id",
      label: "ID",
      width: "120"
    }), _createVNode(_component_el_table_column, {
      prop: "cabin_id",
      label: "驾驶舱ID",
      width: "160"
    }), _createVNode(_component_el_table_column, {
      prop: "duration",
      label: "在线时长",
      width: "160"
    }), _createVNode(_component_el_table_column, {
      prop: "duration_total",
      label: "远程驾驶累计时长",
      width: "200"
    }), _createVNode(_component_el_table_column, {
      prop: "distance_total",
      label: "累计行驶里程",
      width: "180"
    }), _createVNode(_component_el_table_column, {
      prop: "max_speed",
      label: "最高时速",
      width: "180"
    }), _createVNode(_component_el_table_column, {
      prop: "driver",
      label: "驾驶员",
      width: "160"
    }), _createVNode(_component_el_table_column, {
      prop: "order_num",
      label: "订单个数",
      width: "160"
    }), _createVNode(_component_el_table_column, {
      fixed: "right",
      label: "操作",
      width: "180"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        link: "",
        type: "primary",
        size: "small",
        onClick: $options.handleToDetail
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("查看详情")])),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_pagination, {
    class: "pagination",
    layout: "prev, pager, next",
    total: 1000,
    onCurrentChange: $options.handleCurrentChange
  }, null, 8, ["onCurrentChange"])])])]);
}