import "core-js/modules/es.array.push.js";
import Video from '@/components/Video.vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'CarMonitorQueryView',
  components: {
    Video
  },
  data() {
    return {
      dateRange: null,
      info: null,
      currentPage: 1,
      pageSize: 10,
      videoItems: [{
        id: 1,
        diviceId: 5598,
        saveType: 0,
        filepath: "htsd",
        locationType: 1,
        fileType: 1,
        orderId: 1,
        startTime: "2024-12-11 14:48:38",
        endTime: "2024-12-11 14:58:51"
      }],
      total: 0,
      typeItems: []
    };
  },
  mounted() {
    this.requestTypeList(10);
    this.requestEmergencyVideoList(this.currentPage, this.pageSize);
  },
  unmounted() {},
  methods: {
    handleFilter() {
      if (this.dateRange === null) {
        ElMessage.info('请选择时间范围!');
        return;
      }
      const startDate = this.dateRange[0];
      const endDate = this.dateRange[1];
      console.log('startDate = ', this.dateRange[0]);
      console.log('endDate = ', this.dateRange[1]);
      this.currentPage = 1;
      this.requestEmergencyVideoList(this.currentPage, this.pageSize, startDate, endDate);
    },
    handleCurrentChange(page) {
      console.log(page);
      this.currentPage = page;
      this.requestEmergencyVideoList(this.currentPage, this.pageSize);
    },
    handleToDetail(index) {
      console.log(index);
      this.$router.push('/carmonitordetail');
    },
    handleToDetail(index) {
      const data = this.videoItems[index];
      this.$router.push({
        name: 'EmergencyQueryDetailView',
        query: {
          deviceName: data.deviceName,
          deviceId: data.deviceId,
          filepath: data.filepath,
          orderId: data.orderId,
          saveType: data.saveType,
          sn: data.sn,
          startTime: data.startTime,
          endTime: data.endTime
        }
      });
    },
    // 请求紧急视频列表
    requestEmergencyVideoList(pageNum, pageSize, startTime, endTime) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('当前未登录!');
        return;
      }
      this.$axios({
        method: 'post',
        url: '/vedio/queryByPage',
        timeout: 10000,
        //data: {'pageNum': pageNum, 'pageSize': pageSize, 'fileType': 0, 'locationType': 0, 'saveType': 0, "startTime": startTime, "endTime": endTime}
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize,
          'saveType': 0
        }
      }).then(response => {
        this.info = response;
        console.log(JSON.stringify(response));
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取紧急视频列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取紧急视频列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.videoItems = [];
        for (var row of root.rows) {
          for (var typeItem of this.typeItems) {
            if (typeItem.typeValue === row.fileType) {
              row['fileTypeName'] = typeItem.typeName;
            }
          }
          this.videoItems.push(row);
        }
        console.log("获取紧急视频列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取紧急视频列表失败, ' + error)
      }).finally(() => {});
    },
    requestTypeList(type) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'type': type
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取文件类型列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取文件类型列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        this.typeItems = root.rows;
        console.log("获取文件类型列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取文件类型列表失败, ' + error)
      }).finally(() => {});
    }
  }
};