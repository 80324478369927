import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CarManagerView from '@/views/CarManagerView.vue'
import CabinManagerView from '@/views/CabinManagerView.vue'
import MonitorView from '@/views/MonitorView.vue'
import MonitorDetailView from '@/views/MonitorDetailView.vue'
import CabinInfoView from '@/views/CabinInfoView.vue'
import ProfileView from '@/views/ProfileView.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import CarMonitorQueryView from '@/views/CarMonitorQueryView.vue'
import CarMonitorDetailView from '@/views/CarMonitorDetailView.vue'
import CabinHistoryQueryView from '@/views/CabinHistoryQueryView.vue'
import EmergencyQueryView from '@/views/EmergencyQueryView.vue'
import EmergencyQueryDetailView from '@/views/EmergencyQueryDetailView.vue'
import UserManagerView from '@/views/UserManagerView.vue'
import LoginLogView from '@/views/LoginLogView.vue'
import OperationLogView from '@/views/OperationLogView.vue'
import RemoteManagerView from '@/views/RemoteManagerView.vue'
import RemoteManagerDetailView from '@/views/RemoteManagerDetailView.vue'
import TypeManagerView from '@/views/TypeManagerView.vue'
import { requiredNumber } from 'element-plus/es/components/table-v2/src/common'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireLogin: true
    },
    children: [
      {
        path: 'carmanager',
        component: CarManagerView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'cabinmanager',
        component: CabinManagerView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'cabininfo',
        component: CabinInfoView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'monitor',
        component: MonitorView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'monitordetail',
        name: 'MonitorDetailView',
        component: MonitorDetailView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'carmonitorquery',
        component: CarMonitorQueryView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'carmonitordetail',
        component: CarMonitorDetailView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'cabinhistory',
        component: CabinHistoryQueryView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'emergencyquery',
        component: EmergencyQueryView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'emergencyquerydetail',
        name: 'EmergencyQueryDetailView',
        component: EmergencyQueryDetailView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'usermanager',
        component: UserManagerView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'loginlog',
        component: LoginLogView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'operationlog',
        component: OperationLogView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'typemanager',
        component: TypeManagerView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'remotemanager',
        component: RemoteManagerView,
        meta: {
          requireLogin: true
        }
      },
      {
        path: 'remotemanagerdetail',
        name: 'RemoteManagerDetailView',
        component: RemoteManagerDetailView,
        meta: {
          requireLogin: true
        }
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
    meta: {
      requireLogin: false
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/change_password',
    name: 'change_password',
    component: ChangePassword,
    meta: {
      requireLogin: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
