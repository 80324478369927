import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "container-gear"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "gear gear-p",
    style: _normalizeStyle($data.stylesheet_p)
  }, "P", 4), _createElementVNode("div", {
    class: "gear gear-r",
    style: _normalizeStyle($data.stylesheet_r)
  }, "R", 4), _createElementVNode("div", {
    class: "gear gear-n",
    style: _normalizeStyle($data.stylesheet_n)
  }, "N", 4), _createElementVNode("div", {
    class: "gear gear-d",
    style: _normalizeStyle($data.stylesheet_d)
  }, "D", 4)]);
}