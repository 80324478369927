import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "home-layout"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Odometer = _resolveComponent("Odometer");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_Position = _resolveComponent("Position");
  const _component_Van = _resolveComponent("Van");
  const _component_PieChart = _resolveComponent("PieChart");
  const _component_setting = _resolveComponent("setting");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_el_aside = _resolveComponent("el-aside");
  const _component_router_view = _resolveComponent("router-view");
  const _component_el_main = _resolveComponent("el-main");
  const _component_el_container = _resolveComponent("el-container");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_container, {
    class: "home-layout-container"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_aside, {
      class: "home-aside-nav",
      width: "200px"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_scrollbar, null, {
        default: _withCtx(() => [_createVNode(_component_el_menu, {
          "default-openeds": []
        }, {
          default: _withCtx(() => [_createVNode(_component_el_sub_menu, {
            index: "1"
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_Odometer)]),
              _: 1
            }), _cache[9] || (_cache[9] = _createTextVNode("远程监控 "))]),
            default: _withCtx(() => [_createVNode(_component_el_menu_item, {
              index: "1-1",
              onClick: _cache[0] || (_cache[0] = $event => $options.toView('/monitor'))
            }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [_createTextVNode("运营监控")])),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_sub_menu, {
            index: "2"
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_Position)]),
              _: 1
            }), _cache[11] || (_cache[11] = _createTextVNode("远驾调度 "))]),
            default: _withCtx(() => [_createVNode(_component_el_menu_item, {
              index: "2-1",
              onClick: _cache[1] || (_cache[1] = $event => $options.toView('/remotemanager'))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [_createTextVNode("远驾调度")])),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_sub_menu, {
            index: "3"
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_Van)]),
              _: 1
            }), _cache[13] || (_cache[13] = _createTextVNode("车舱管理 "))]),
            default: _withCtx(() => [_createVNode(_component_el_menu_item, {
              index: "3-1",
              onClick: _cache[2] || (_cache[2] = $event => $options.toView('/cabinmanager'))
            }, {
              default: _withCtx(() => _cache[14] || (_cache[14] = [_createTextVNode("舱端设备管理")])),
              _: 1
            }), _createVNode(_component_el_menu_item, {
              index: "3-2",
              onClick: _cache[3] || (_cache[3] = $event => $options.toView('/carmanager'))
            }, {
              default: _withCtx(() => _cache[15] || (_cache[15] = [_createTextVNode("车端设备管理")])),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_sub_menu, {
            index: "4"
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_PieChart)]),
              _: 1
            }), _cache[16] || (_cache[16] = _createTextVNode("数据管理 "))]),
            default: _withCtx(() => [_createVNode(_component_el_menu_item, {
              index: "4-3",
              onClick: _cache[4] || (_cache[4] = $event => $options.toView('/emergencyquery'))
            }, {
              default: _withCtx(() => _cache[17] || (_cache[17] = [_createTextVNode("车端紧急视频查询")])),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_sub_menu, {
            index: "5"
          }, {
            title: _withCtx(() => [_createVNode(_component_el_icon, null, {
              default: _withCtx(() => [_createVNode(_component_setting)]),
              _: 1
            }), _cache[18] || (_cache[18] = _createTextVNode("系统管理 "))]),
            default: _withCtx(() => [_createVNode(_component_el_menu_item, {
              index: "5-1",
              onClick: _cache[5] || (_cache[5] = $event => $options.toView('/usermanager'))
            }, {
              default: _withCtx(() => _cache[19] || (_cache[19] = [_createTextVNode("用户管理")])),
              _: 1
            }), _createVNode(_component_el_menu_item, {
              index: "5-2",
              onClick: _cache[6] || (_cache[6] = $event => $options.toView('loginlog'))
            }, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [_createTextVNode("登录日志查询")])),
              _: 1
            }), _createVNode(_component_el_menu_item, {
              index: "5-3",
              onClick: _cache[7] || (_cache[7] = $event => $options.toView('/operationlog'))
            }, {
              default: _withCtx(() => _cache[21] || (_cache[21] = [_createTextVNode("操作日志查询")])),
              _: 1
            }), _createVNode(_component_el_menu_item, {
              index: "5-4",
              onClick: _cache[8] || (_cache[8] = $event => $options.toView('/typemanager'))
            }, {
              default: _withCtx(() => _cache[22] || (_cache[22] = [_createTextVNode("类型管理")])),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_main, {
      class: "home-main"
    }, {
      default: _withCtx(() => [_createVNode(_component_router_view)]),
      _: 1
    })]),
    _: 1
  })]);
}