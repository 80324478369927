import Header from '@/components/Header.vue';
export default {
  data() {
    return {
      username: ''
    };
  },
  mounted() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo != null) {
      this.username = userInfo.userName;
    }
  }
};