import CabinCell from '@/components/CabinCell.vue';
export default {
  components: {
    CabinCell
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  unmounted() {}
};