export default {
  props: {
    rpm: {
      type: Number,
      default: 0
    }
  },
  watch: {
    rpm(newValue, oldValue) {
      this.drawDashboard(newValue);
    }
  },
  mounted() {
    this.drawDashboard(this.rpm);
  },
  methods: {
    drawDashboard(rpm) {
      //const canvas = this.$refs.canvas
      const canvas = document.getElementById("canvas-rotate-speed");
      const ctx = canvas.getContext('2d');
      // 在 Canvas 上进行绘图操作
      //ctx.fillStyle = 'white'
      //ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2 + 40;
      const outerCircleRadius = 140;
      const innerCircleRadius = outerCircleRadius / 9.0;

      // 外弧形圆环
      ctx.beginPath();
      ctx.arc(centerX, centerY, outerCircleRadius, Math.PI * (5 / 6), Math.PI * (1 / 6));
      ctx.lineWidth = 4;
      ctx.strokeStyle = '#000';
      ctx.stroke();
      ctx.closePath();

      // 刻度线
      const lineNums = 60;
      for (var i = 0; i <= lineNums; ++i) {
        if (i > 5 && i < 25) {
          continue;
        }
        ctx.beginPath();
        var angle = 2 * Math.PI / 60 * i;
        var dx = Math.cos(angle) * (outerCircleRadius - 15);
        var dy = Math.sin(angle) * (outerCircleRadius - 15);
        var dx2 = Math.cos(angle) * (outerCircleRadius - 7);
        var dy2 = Math.sin(angle) * (outerCircleRadius - 7);
        /*
        if (i % 5 === 0 && i != 25 && i != 30) {
          ctx.lineWidth = 2
          ctx.strokeStyle = '#000'
        } else if (i >= 25 && i <= 30) {
          ctx.strokeStyle = "red"
        } else {
          ctx.lineWidth = 1
          ctx.strokeStyle = "green"
        }
        */
        if (i % 5 === 0) {
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#000';
        } else {
          ctx.lineWidth = 1;
          ctx.strokeStyle = "green";
        }
        //ctx.moveTo(canvas.width / 2 + dx, canvas.height / 2 + dy)
        //ctx.lineTo(canvas.width / 2 + dx2, canvas.height / 2 + dy2)
        ctx.moveTo(centerX + dx, centerY + dy);
        ctx.lineTo(centerX + dx2, centerY + dy2);
        ctx.stroke();
      }

      // 画数字
      var numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
      ctx.font = "16px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      for (var i = 0; i < 12; ++i) {
        ctx.fillStyle = '#000';
        var angle = 2 * Math.PI / 12 * numbers[i] - 3.14 / 2;
        var dx = Math.cos(angle) * (outerCircleRadius - 35 + 3);
        var dy = Math.sin(angle) * (outerCircleRadius - 35 + 3);
        switch (i) {
          case 0:
            //ctx.fillText(25, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(5, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 1:
            //ctx.fillText(30, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(6, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 2:
            //ctx.fillText(35, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(7, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 3:
            //ctx.fillText(40, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(8, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 7:
            //ctx.fillText(0, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(0, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 8:
            //ctx.fillText(5, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(1, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 9:
            //ctx.fillText(10, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(2, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 10:
            //ctx.fillText(15, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(3, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          case 11:
            //ctx.fillText(20, canvas.width / 2 + dx, canvas.height / 2 + dy)
            ctx.fillText(4, centerX + dx, centerY + dy);
            ctx.fill();
            break;
          default:
            break;
        }
      }

      //console.log('====> rpm: ' + rpm)
      var scale = 2.0;
      if (rpm >= 8000) {
        scale = 10.0;
      } else if (rpm <= 0) {
        scale = 2.0;
      } else {
        scale = rpm / 8000.0 * 8.0 + 2.0;
      }

      // 0: (2 * Math.PI / 12.0) * 2
      // 40: (2 * Math.PI / 12.0) * 10
      var angle = 2 * Math.PI / 12.0 * scale;
      // ctx.clearRect(0, 0, canvas.width, canvas.height)
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = canvas.width / 100;
      ctx.strokeStyle = 'red';
      // 平移坐标点(注意：坐标系原点先平移，再旋转)
      //ctx.translate(canvas.width / 2, canvas.height / 2)
      ctx.translate(centerX, centerY);
      // 旋转坐标系
      ctx.rotate(angle);
      // 坐标原点变化之后再进行实际的绘图
      ctx.moveTo(0, -10);
      ctx.lineTo(0, outerCircleRadius / 1.5);
      ctx.stroke();
      ctx.restore();

      // 绘制指针根部圆圈
      // ctx.save()
      ctx.lineWidth = canvas.width / 50;
      ctx.fillStyle = 'lightSlateGray';
      ctx.beginPath();
      //ctx.arc(canvas.width / 2, canvas.height / 2, innerCircleRadius, 0, Math.PI * 2)
      ctx.arc(centerX, centerY, innerCircleRadius, 0, Math.PI * 2);
      ctx.fill();
      // ctx.restore()

      ctx.font = "18px Arial";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      //ctx.fillText("车速（km/h）", canvas.width / 2, canvas.height / 2 + 50);
      ctx.fillText("转速（x1000r/min）", centerX + 6, centerY + 50 + 12);
      ctx.fill();
    }
  }
};