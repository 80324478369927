import { BaiduMap } from 'vue-baidu-map-3x';
import Gear from '@/components/Gear.vue';
import TurnLeftOffImage from '@/assets/turn_left_off.png';
import TurnRightOffImage from '@/assets/turn_right_off.png';
import flvjs from 'flv.js/dist/flv.min.js';
import SpeedPanel from '@/components/SpeedPanel.vue';
import RotateSpeedPanel from '@/components/RotateSpeedPanel.vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  components: {
    BaiduMap,
    Gear,
    SpeedPanel,
    RotateSpeedPanel
  },
  props: {
    deviceId: String
  },
  data() {
    return {
      info: null,
      map: null,
      marker: null,
      MAX_RECONNECT_TRIES: 99999,
      reconnectTries: 0,
      isReconnect: true,
      frontFlvPlayer: null,
      backFlvPlayer: null,
      leftFlvPlayer: null,
      rightFlvPlayer: null,
      insideFlvPlayer: null,
      avmFlvPlayer: null,
      websocket: null,
      wsUrl: 'wss://192.168.19.15:8899',
      timer: null,
      carStatusTimer: null,
      // 平均速度 km/h
      avgSpeed: 0,
      // 驾驶距离 m
      distance: 0,
      // 最高速度 km/h
      maxSpeed: 0,
      // 最低速度 km/h
      minSpeed: 0,
      // 远程驾驶距离 单位m
      remoteDistance: 0,
      // 运营总时长 单位s
      sunRunTime: 0,
      deviceName: null,
      deviceId: null,
      sn: null,
      vin: null,
      operate: null,
      typeName: null,
      carNo: null,
      rpm: 0,
      battery: 0,
      brake: 0,
      gear: 'N',
      lat: 0,
      lon: 0,
      mode: 0,
      speed: 0,
      steer: 0,
      throttle: 0,
      turn_light: 0,
      turnLeftSrc: require("@/assets/turn_left_off.png"),
      turnRightSrc: require("@/assets/turn_right_off.png"),
      front_url: null,
      back_url: null,
      left_url: null,
      right_url: null,
      inside_url: null,
      avm_url: null,
      stylesheet_remote: "border-radius: 0px; height: 36px;",
      stylesheet_auto: "border-radius: 0px; height: 36px;",
      stylesheet_manual: "border-radius: 0px; height: 36px;",
      stylesheet_normal: "border-radius: 0px; height: 36px; background: #666666;",
      stylesheet_selected: "border-radius: 0px; height: 36px; background: #b0ebd9;"
    };
  },
  methods: {
    createVideoPlayer(element_id, url) {
      console.log('====> element_id:' + element_id + ', url:' + url);
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById(element_id);
        var flvPlayer = flvjs.createPlayer({
          isLive: true,
          hasAudio: false,
          hasVideo: true,
          type: 'flv',
          url: 'http://192.168.19.103:80/live?port=1935&app=live&stream=video01' //你的url地址
        });
        flvPlayer.attachMediaElement(videoElement);
        flvPlayer.load();
        //flvPlayer.play();
        //报错重连
        flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
          console.log('errType:', errType);
          console.log('errorDetail:', errDetail);
          if (this.flvPlayer) {
            this.destoryVideoPlayer(this.flvPlayer);
            this.createVideoPlayer(element_id, url);
          }
        });
        return flvPlayer;
      }
    },
    destoryVideoPlayer(player) {
      if (player) {
        //player.pause()
        player.unload();
        player.detachMediaElement();
        player.destroy();
        player = null;
      }
    },
    initBaiduMap() {
      var map = new BMapGL.Map("map-baidu");
      var point = new BMapGL.Point(117.372458, 39.174445);
      map.centerAndZoom(point, 20);
      this.map = map;
    },
    addMarker(lon, lat) {
      console.log('====> lon:' + lon + ', lat:' + lat);
      if (this.marker != null) {
        this.map.removeOverlay(this.marker);
      }
      var point = new BMapGL.Point(lon, lat);
      this.map.centerAndZoom(point, 20);
      var myIcon = new BMapGL.Icon("./map_car.png", new BMapGL.Size(52, 52), {
        // 指定定位位置。  
        // 当标注显示在地图上时，其所指向的地理位置距离图标左上   
        // 角各偏移10像素和25像素。您可以看到在本例中该位置即是  
        // 图标中央下端的尖角位置。   
        anchor: new BMapGL.Size(26, 52)
        // 设置图片偏移。  
        // 当您需要从一幅较大的图片中截取某部分作为标注图标时，您  
        // 需要指定大图的偏移位置，此做法与css sprites技术类似。   
        // imageOffset: new BMapGL.Size(0, 0)  // 设置图片偏移   
      });
      // 创建Marker标注，使用小车图标
      var marker = new BMapGL.Marker(point, {
        icon: myIcon
      });

      // marker.addEventListener("click", function() {   
      //   alert("您点击了标注");   
      // });
      this.map.addOverlay(marker); // 将标注添加到地图中
      this.marker = marker;
    },
    initWebsocket() {
      this.isReconnect = true;
      this.websocket = new WebSocket(this.wsUrl);
      // this.websocket.onmessage = function(event) {
      //   console.log(`收到消息：${event.data}`)
      // }
      this.websocket.onopen = this.websocketOpen;
      this.websocket.onerror = this.websocketError;
      this.websocket.onmessage = this.websocketOnMessage;
      this.websocket.onclose = this.closeWebsocket;
    },
    destoryWebsocket() {
      this.isReconnect = false;
      this.websocket.close();
    },
    websocketOpen() {
      console.log('websocket onOpen', this.websocket.readyState);
      this.reconnectTries = 0;
      // WebSocket.CONNECTING  0   连接正在进行，但还未建立
      // WebSocket.OPEN        1   连接已经建立，消息可以在客户端和服务端进行传递
      // WebSocket.CLOSING     2   连接正在进行关闭握手
      // WebSocket.CLOSED      3   连接已经关闭，不能打开
      if (this.websocket.readyState === WebSocket.OPEN) {
        console.log('websocket已连接！');
        this.startHeartbeat();
      }
    },
    websocketError() {
      console.log('websocket onError', this.websocket.readyState);
    },
    websocketOnMessage(event) {
      //console.log(`收到消息：${event.data}`)
      let root = JSON.parse(event.data);
      const op = root['op'];
      const dev = root['dev'];
      //console.log('====> op: ' + op + ', dev: ' + dev)
      if (op === 'GET_CAR_STATE_RPY' /* && dev === this.deviceName*/) {
        const data = root['data'];
        if (data != null) {
          this.rpm = data['RPM'];
          this.battery = data['battery'];
          this.brake = data['break'];
          this.gear = data['gear'];
          const mode = data['mode'];
          if (mode === 1) {
            this.mode = '远程驾驶';
            this.stylesheet_auto = this.stylesheet_normal;
            this.stylesheet_remote = this.stylesheet_selected;
            this.stylesheet_manual = this.stylesheet_normal;
          } else if (mode === 2) {
            this.mode = '人工驾驶';
            this.stylesheet_auto = this.stylesheet_normal;
            this.stylesheet_remote = this.stylesheet_normal;
            this.stylesheet_manual = this.stylesheet_selected;
          } else {
            this.mode = '域控自动驾驶';
            this.stylesheet_auto = this.stylesheet_selected;
            this.stylesheet_remote = this.stylesheet_normal;
            this.stylesheet_manual = this.stylesheet_normal;
          }
          this.speed = data['speed'];
          this.steer = data['steer'];
          this.throttle = data['throttle'];
          const turn_light = data['turn_light'];
          if (turn_light === 1) {
            this.turnLeftSrc = require("@/assets/turn_left_on.png");
            this.turnRightSrc = require("@/assets/turn_right_off.png");
          } else if (turn_light === 2) {
            this.turnLeftSrc = require("@/assets/turn_left_off.png");
            this.turnRightSrc = require("@/assets/turn_right_on.png");
          } else if (turn_light === 3) {
            this.turnLeftSrc = require("@/assets/turn_left_on.png");
            this.turnRightSrc = require("@/assets/turn_right_on.png");
          } else {
            this.turnLeftSrc = require("@/assets/turn_left_off.png");
            this.turnRightSrc = require("@/assets/turn_right_off.png");
          }
          const lon = 117.372458;
          const lat = 39.174445;
          this.addMarker(lon, lat);
        }
      }
    },
    closeWebsocket() {
      console.log('websocket onClose', this.websocket.readyState);
      this.stopHeartbeat();
      this.websocket.close();
      this.reconnect();
    },
    sendMessage(type) {
      if (this.websocket.readyState === WebSocket.OPEN) {
        const ts = new Date().getTime();
        this.websocket.send(JSON.stringify({
          op: type,
          dev: this.deviceName,
          cmdid: ts
        }));
      }
    },
    reconnect() {
      if (this.reconnectTries < this.MAX_RECONNECT_TRIES) {
        this.reconnectTries++;
        setTimeout(() => {
          this.initWebsocket();
        }, 2000);
      } else {
        console.error('达到最大重连次数，停止重连');
      }
    },
    handleSuccessOrderNum() {
      this.sendMessage('GET_CAR_STATE');
    },
    startHeartbeat() {
      this.timer = setInterval(() => {
        const ts = new Date().getTime();
        if (this.websocket != null) {
          this.websocket.send(JSON.stringify({
            type: 'heartbeat',
            timestamp: ts
          }));
        }
        //console.log('==> timerout:' + ts)
      }, 15 * 1000); // 每15s执行一次
    },
    stopHeartbeat() {
      if (this.timer != null) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
    startFetchCarStatus() {
      this.carStatusTimer = setInterval(() => {
        this.sendMessage('GET_CAR_STATE');
      }, 1000); // 每1s执行一次
    },
    stopFetchCarStatus() {
      if (this.carStatusTimer != null) {
        clearInterval(this.carStatusTimer);
        this.carStatusTimer = null;
      }
    },
    // 获取统计信息
    requestStatisticsInfo(deviceId) {
      console.log('====> deviceId: ', deviceId);
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('请重新登录!');
        return;
      }
      const userId = userInfo.userId;
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth();
      const date = currentDate.getDate();
      const startTime = `${year}-${month}-${date} 00:00:00`;
      const endTime = `${year}-${month}-${date} 23:59:59`;
      this.$axios({
        method: 'post',
        url: '/order/getOrderStat',
        timeout: 10000,
        data: {
          'deviceId': deviceId,
          'startTime': startTime,
          'endTime': endTime
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取今日运营信息失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取今日运营信息失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const data = root.data;
        this.avgSpeed = data.avgSpeed;
        this.distance = data.distance;
        this.maxSpeed = data.maxSpeed;
        this.minSpeed = data.minSpeed;
        this.remoteDistance = data.remoteDistance;
        this.sunRunTime = data.sunRunTime;
        console.log("获取今日运营信息成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取今日运营信息失败, ' + error)
      }).finally(() => {});
    },
    // 获取/关闭视频链接
    requestVideos(deviceId, isOn) {
      console.log('====> deviceId: ' + deviceId + ', isOn: ' + isOn);
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) {
        ElMessage.error('请重新登录!');
        return;
      }
      const userId = userInfo.userId;
      this.$axios({
        method: 'post',
        url: '/control/setVid',
        timeout: 10000,
        data: {
          'deviceId': deviceId,
          'userId': userId,
          'all': isOn ? 'on' : 'off'
        }
      }).then(response => {
        this.info = response;
        console.log('resp:' + JSON.stringify(response));
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取视频链接失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取视频链接失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const data = root.data;
        const urls = data.url;
        const front_url = urls['front'];
        const back_url = urls['back'];
        const left_url = urls['left'];
        const right_url = urls['right'];
        const inside_url = urls['inside'];
        const avm_url = urls['avm'];
        const iframeFront = document.getElementById('iframe-front');
        if (iframeFront && front_url != null) {
          iframeFront.src = front_url;
        }
        const iframe_back = document.getElementById('iframe-back');
        if (iframe_back && back_url != null) {
          iframe_back.src = back_url;
        }
        const iframe_left = document.getElementById('iframe-left');
        if (iframe_left && left_url != null) {
          iframe_left.src = left_url;
        }
        const iframe_right = document.getElementById('iframe-right');
        if (iframe_right && right_url != null) {
          iframe_right.src = right_url;
        }
        const iframe_inside = document.getElementById('iframe-inside');
        if (iframe_inside && inside_url != null) {
          iframe_inside.src = inside_url;
        }
        const iframe_avm = document.getElementById('iframe-avm');
        if (iframe_avm && avm_url != null) {
          iframe_avm.src = avm_url;
        }
        // if (this.frontFlvPlayer === null && front_url != null) {
        //   this.frontFlvPlayer = this.createVideoPlayer('video-front', front_url)
        // }
        // if (this.backFlvPlayer === null && back_url != null) {
        //   this.backFlvPlayer = this.createVideoPlayer('video-back', back_url)
        // }
        // if (this.leftFlvPlayer === null && left_url != null) {
        //   this.leftFlvPlayer = this.createVideoPlayer('video-left', left_url)
        // }
        // if (this.rightFlvPlayer === null && right_url != null) {
        //   this.rightFlvPlayer = this.createVideoPlayer('video-right', right_url)
        // }
        // if (this.insideFlvPlayer === null && inside_url != null) {
        //   this.insideFlvPlayer = this.createVideoPlayer('video-inside', inside_url)
        // }
        // if (this.avmFlvPlayer === null && avm_url != null) {
        //   this.avmFlvPlayer = this.createVideoPlayer('video-avm', avm_url)
        // }

        console.log("获取视频链接成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取视频链接失败, ' + error)
      }).finally(() => {});
    }
  },
  mounted() {
    this.wsUrl = this.$wssURL;
    //     fetch('config.json')
    // .then(response => response.json())
    // .then(config => {
    //     console.log(config);
    //     this.wsUrl = config.wss
    // })
    // console.log('2222,' + this.wsUrl)
    // this.frontFlvPlayer = this.createVideoPlayer('video-front', '')
    // this.backFlvPlayer = this.createVideoPlayer('video-back')
    // this.leftFlvPlayer = this.createVideoPlayer('video-left')
    // this.rightFlvPlayer = this.createVideoPlayer('video-right')
    // this.insideFlvPlayer = this.createVideoPlayer('video-inside')
    // this.avmFlvPlayer = this.createVideoPlayer('video-avm')
    this.initBaiduMap();
    this.initWebsocket();
    this.requestStatisticsInfo(this.$route.query.deviceId);

    //console.log('====> vin: ' + this.$route.query.vin)
    this.deviceName = this.$route.query.deviceName;
    this.deviceId = this.$route.query.deviceId;
    this.sn = this.$route.query.sn;
    this.vin = this.$route.query.vin;
    this.operate = this.$route.query.operate;
    this.typeName = this.$route.query.typeName;
    this.carNo = this.$route.query.carNo;
    this.startFetchCarStatus();
    this.requestVideos(this.deviceId, true);
  },
  unmounted() {
    this.destoryVideoPlayer(this.frontFlvPlayer);
    this.destoryVideoPlayer(this.backFlvPlayer);
    this.destoryVideoPlayer(this.leftFlvPlayer);
    this.destoryVideoPlayer(this.rightFlvPlayer);
    this.destoryVideoPlayer(this.insideFlvPlayer);
    this.destoryVideoPlayer(this.avmFlvPlayer);
    this.stopHeartbeat();
    this.destoryWebsocket();
    this.stopFetchCarStatus();
  }
};