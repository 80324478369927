import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "container-operation-log"
};
const _hoisted_2 = {
  class: "div-header"
};
const _hoisted_3 = {
  class: "div-body"
};
const _hoisted_4 = {
  class: "container-pagination"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_text = _resolveComponent("el-text");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_el_pagination = _resolveComponent("el-pagination");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_text, {
    class: "title",
    size: "large"
  }, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("操作日志查询")])),
    _: 1
  })]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_scrollbar, null, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.logList, log => {
      return _openBlock(), _createElementBlock("li", {
        class: "li-log",
        key: log.operateId
      }, "操作用户: " + _toDisplayString(log.userId) + " 操作ip:120.237.99.10 操作时间: " + _toDisplayString(log.operateTime) + " " + _toDisplayString(log.type), 1);
    }), 128))]),
    _: 1
  })]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_pagination, {
    class: "pagination",
    layout: "prev, pager, next",
    total: $data.total,
    onCurrentChange: $options.handleCurrentChange
  }, null, 8, ["total", "onCurrentChange"])])]);
}