import "core-js/modules/es.array.push.js";
import { dataType } from 'element-plus/es/components/table-v2/src/common';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  name: 'CarManagerView',
  components: {},
  data() {
    return {
      searchkeyword: null,
      createdType: 1,
      deleteDialogVisible: false,
      selectedIndex: -1,
      deleteUserName: null,
      changeDeviceType: 'add',
      currentPage: 1,
      pageNum: 10,
      centerDialogVisible: false,
      addDialog: false,
      addDialogTitle: null,
      isEditDisabled: false,
      loading: false,
      formLabelWidth: 80,
      form: {
        name: '',
        vin: '',
        type: '',
        status: '',
        sn: '',
        license_plate: ''
      },
      statusValue: '-1',
      statusItems: [{
        value: '-1',
        label: '所有状态'
      }, {
        value: '0',
        label: '非运营'
      }, {
        value: '1',
        label: '运营'
      }],
      typeValue: '-1',
      typeFilterItems: [{
        id: '-1',
        type: '1',
        typeName: '所有类型',
        typeValue: '-1'
      }],
      typeItems: [
        /*{
            value: '-1',
            label: '所有类型'
        },
        {
          value: '0',
          label: '教学车'
        },
        {
          value: '1',
          label: '巡逻车'
        },
        {
          value: '2',
          label: '清扫车（小）'
        },
        {
          value: '3',
          label: '清扫车（中）'
        },
        {
          value: '4',
          label: '清扫车（大）'
        },
        {
          value: '5',
          label: '小巴'
        },
        {
          value: '6',
          label: '矿卡'
        },
        {
          value: '7',
          label: '物流车'
        },
        {
          value: '8',
          label: '集卡'
        }*/
      ],
      tableData: [
        /*{
        activeState: 0,
        aliasName: "",
        bindDeviceId: 0,
        carNo: "",
        craeted: "",
        createdTime: "",
        createdType: 0,
        deviceId: 0,
        deviceName: "",
        isDel: 0,
        name: "",
        number: 0,
        operateState: 0,
        operatorId: 0,
        saveVedio: 0,
        sn: "",
        state: 0,
        type: 0,
        useState: 0,
        vehicleId: 0,
        vin: ""
        }*/
      ],
      total: 0
    };
  },
  mounted() {
    this.requestTypeList(4);
    this.requestCarDeviceList(this.currentPage, this.pageNum, this.statusValue, this.typeValue);
  },
  unmounted() {},
  methods: {
    handleSearch() {
      console.log('searchKwyword = ', this.searchkeyword);
      if (this.searchkeyword === null || this.searchkeyword === undefined || this.searchkeyword.trim() === '') {
        this.currentPage = 1;
        this.requestCarDeviceList(this.currentPage, this.pageNum, this.statusValue, this.typeValue);
      } else {
        this.requestSearchCarDevice(this.searchkeyword);
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.requestCarDeviceList(this.currentPage, this.pageNum, this.statusValue, this.typeValue);
    },
    handleConfirmDelete() {
      this.centerDialogVisible = false;
      console.log('确认删除');
    },
    cancelForm() {
      this.addDialog = false;
    },
    handleSubmit() {
      if (this.changeDeviceType === 'add') {
        this.requestAddCarDevice(this.form.name, this.form.status, this.form.type, this.form.sn, this.form.vin, this.form.license_plate);
      } else if (this.changeDeviceType === 'update') {
        const device = this.tableData[this.selectedIndex];
        const deviceId = device.deviceId;
        this.requestUpdateCarDevice(deviceId, this.form.name, this.form.status, this.form.type, this.form.sn, this.form.vin, this.form.license_plate);
      }
    },
    handleAddDevice() {
      this.form.name = '';
      this.form.vin = '';
      this.form.type = '';
      this.form.status = '';
      this.form.sn = '';
      this.form.license_plate = '';
      this.changeDeviceType = 'add';
      this.addDialogTitle = '添加远程驾驶车辆信息：';
      this.isEditDisabled = true;
      this.addDialog = true;
    },
    handleChangeDevice(index) {
      this.selectedIndex = index;
      const device = this.tableData[index];
      this.form.name = device.deviceName;
      this.form.vin = device.vin;
      this.form.type = device.type;
      this.form.status = device.operateState.toString();
      this.form.sn = device.sn;
      this.form.license_plate = device.carNo;
      this.changeDeviceType = 'update';
      this.addDialogTitle = '修改远程驾驶车辆信息：';
      this.isEditDisabled = true;
      this.addDialog = true;
    },
    handleDeleteDevice(index) {
      this.selectedIndex = index;
      const device = this.tableData[index];
      this.deleteDeviceName = device.deviceName;
      this.deleteDialogVisible = true;
    },
    handleCancelDeleteUser() {
      this.selectedIndex = -1;
      this.deleteDeviceName = null;
      this.deleteDialogVisible = false;
    },
    handleConfirmDeleteUser() {
      this.deleteDialogVisible = false;
      const device = this.tableData[this.selectedIndex];
      const devId = device.deviceId;
      this.requestDeleteCarDevice(devId);
    },
    // 筛选车端设备
    handleFilter() {
      this.currentPage = 1;
      this.requestCarDeviceList(this.currentPage, this.pageSize, this.statusValue, this.typeValue);
    },
    // 请求车端设备列表
    requestCarDeviceList(pageNum, pageSize, state, type) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (state === '-1') {
        state = '';
      }
      if (type === '-1') {
        type = '';
      }
      this.$axios({
        method: 'post',
        url: '/device/queryVehicleByPage',
        timeout: 10000,
        data: {
          'pageNum': pageNum,
          'pageSize': pageSize,
          'createdType': this.createdType,
          'operateState': state,
          'type': type
        }
      }).then(response => {
        //this.info = response
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取车端设备列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取车端设备列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.total = root.total;
        this.tableData = root.rows;
        console.log("获取车端设备列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取车端设备列表失败, ' + error)
      }).finally(() => {});
    },
    // 请求添加车端设备
    requestAddCarDevice(name, state, type, sn, vin, carNo) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (userId === null || userId === undefined || userId.trim() === '') {
        ElMessage.error('userId为空!');
        return;
      }
      if (!(this.createdType === 0 || this.createdType === 1)) {
        ElMessage.error('createdType error!');
        return;
      }
      if (name === null || name === undefined || name.trim() === '') {
        ElMessage.info('车辆编号为空!');
        return;
      }
      if (state === '') {
        ElMessage.info('运营状态为空!');
        return;
      }
      if (type === '') {
        ElMessage.info('设备类型为空!');
        return;
      }
      if (sn === null || sn === undefined || sn.trim() === '') {
        ElMessage.error('车辆sn为空!');
        return;
      }
      if (vin === null || vin === undefined || vin.trim() === '') {
        ElMessage.error('车辆vin为空!');
        return;
      }
      if (carNo === null || carNo === undefined || carNo.trim() === '') {
        ElMessage.error('车牌号为空!');
        return;
      }
      this.$axios({
        method: 'post',
        url: '/device/add',
        timeout: 10000,
        data: {
          'craeted': userId,
          'createdType': this.createdType,
          'deviceName': name,
          'number': 0,
          'operateState': state,
          'sn': sn,
          'type': type,
          'vin': vin,
          'carNo': carNo
        }
      }).then(response => {
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("添加车端设备失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("添加车端设备失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        ElMessage.success('添加车端设备成功!');
        console.log("添加车端设备成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('添加车端设备失败, ' + error)
      }).finally(() => {});
    },
    // 请求更新车端设备
    requestUpdateCarDevice(deviceId, name, state, type, sn, vin, carNo) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      if (userId === null || userId === undefined || userId.trim() === '') {
        ElMessage.error('userId为空!');
        return;
      }
      if (!(this.createdType === 0 || this.createdType === 1)) {
        ElMessage.error('createdType error!');
        return;
      }
      if (name === null || name === undefined || name.trim() === '') {
        ElMessage.info('车辆编号为空!');
        return;
      }
      if (state === '') {
        ElMessage.info('运营状态为空!');
        return;
      }
      if (type === '') {
        ElMessage.info('设备类型为空!');
        return;
      }
      if (sn === null || sn === undefined || sn.trim() === '') {
        ElMessage.error('车辆sn为空!');
        return;
      }
      if (vin === null || vin === undefined || vin.trim() === '') {
        ElMessage.error('车辆vin为空!');
        return;
      }
      if (carNo === null || carNo === undefined || carNo.trim() === '') {
        ElMessage.error('车牌号为空!');
        return;
      }
      this.$axios({
        method: 'post',
        url: '/device/update',
        timeout: 10000,
        data: {
          'deviceId': deviceId,
          'craeted': userId,
          'createdType': this.createdType,
          'deviceName': name,
          'number': 0,
          'operateState': state,
          'sn': sn,
          'type': type,
          'vin': vin,
          'carNo': carNo
        }
      }).then(response => {
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("更新车端设备失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("更新车端设备失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        console.log("更新车端设备成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('更新车端设备失败, ' + error)
      }).finally(() => {});
    },
    // 请求删除车端设备
    requestDeleteCarDevice(devId) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      this.$axios({
        method: 'post',
        url: '/device/remove',
        timeout: 10000,
        data: [devId]
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("删除车端设备失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("删除车端设备失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        this.selectedIndex = -1;
        this.deleteDeviceName = null;
        console.log("删除车端设备成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('删除车端设备失败, ' + error)
      }).finally(() => {});
    },
    // 请求搜索设备
    requestSearchCarDevice(deviceId) {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));
      if (userInfo === null) return;
      const userId = userInfo.userId;
      const url = "/device/" + deviceId;
      this.$axios({
        method: 'get',
        url: url,
        timeout: 10000,
        data: {}
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("搜索车端设备失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("搜索车端设备失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        // bugfix: - 目前每次只能搜到一条，返回无total
        this.total = 1; //root.total
        this.tableData = [root.data];
        console.log("搜索车端设备成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('搜索车端设备失败, ' + error)
      }).finally(() => {});
    },
    // 获取设备类型
    requestTypeList(type) {
      this.$axios({
        method: 'post',
        url: '/typeDictionary/queryByPage',
        timeout: 10000,
        data: {
          'pageNum': 0,
          'pageSize': 0,
          'type': type
        }
      }).then(response => {
        this.info = response;
        const httpStatus = response.status;
        if (httpStatus != 200) {
          alert("获取设备类型列表失败，Http状态码：" + httpStatus + "，错误描述：" + response.statusText);
          return;
        }
        const root = response.data;
        const serverStatus = root.code;
        if (serverStatus != 200) {
          alert("获取设备类型列表失败，服务器状态码：" + serverStatus + "，错误描述：" + root.msg);
          return;
        }
        const total = root.total;
        this.typeItems = root.rows;
        for (var row of root.rows) {
          this.typeFilterItems.push(row);
        }
        console.log("获取设备类型列表成功!");
      }).catch(error => {
        console.log(error);
        this.error = error;
        //ElMessage.error('获取设备类型列表失败, ' + error)
      }).finally(() => {});
    }
  }
};